import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function JudicalReview() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };



    return (
        <>
            <div className="page-hero judi">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>JUDICIAL REVIEW</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                If your application for a visitor visa, study permit, work permit, or permanent residence is refused by an officer – or if you face any other unfavorable decision by Immigration, Refugees, and Citizenship Canada – a lawyer can challenge this decision through judicial review before the Federal Court.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    WHAT IS JUDICIAL REVIEW?
                                </a>
                            </li>
                            <li >
                                <a href="#what_is_the_procedure" onClick={(e) => { e.preventDefault(); scrollToSection('what_is_the_procedure'); }}>
                                    WHAT IS THE PROCEDURE?
                                </a>
                            </li>
                            <li>
                                <a href="#step1" onClick={(e) => { e.preventDefault(); scrollToSection('step1'); }}>
                                    STEP 1: Initiation of proceedings
                                </a>
                            </li>
                            <li>
                                <a href="#step2" onClick={(e) => { e.preventDefault(); scrollToSection('step2'); }}>
                                    STEP 2: Determining the prospects for judicial review
                                </a>
                            </li>
                            <li>
                                <a href="#step3" onClick={(e) => { e.preventDefault(); scrollToSection('step3'); }}>
                                    STEP 3: Application Record
                                </a>
                            </li>
                            <li>
                                <a href="#step4" onClick={(e) => { e.preventDefault(); scrollToSection('step4'); }}>
                                    STEP 4: Respondent’s Memorandum and Reply Memorandum
                                </a>
                            </li>
                            <li>
                                <a href="#step5" onClick={(e) => { e.preventDefault(); scrollToSection('step5'); }}>
                                    STEP 5: Hearing Date
                                </a>
                            </li>
                            <li>
                                <a href="#what_are_the_possible_outcomes" onClick={(e) => { e.preventDefault(); scrollToSection('what_are_the_possible_outcomes'); }}>
                                    WHAT ARE THE POSSIBLE OUTCOMES?
                                </a>
                            </li>
                            <li>
                                <a href="#study-service" onClick={(e) => { e.preventDefault(); scrollToSection('study-service'); }}>
                                    OUR SERVICES
                                </a>
                            </li>


                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="two">
                            <h1>
                                WHAT IS JUDICIAL REVIEW?
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                In practice, officers may commit errors in interpreting the law or reviewing your application. The decision-making process can be influenced by various factors, including political considerations, personal biases, and errors arising from the processing of immigration applications by artificial intelligence.
                            </p>
                            <p>
                                Judicial review is a process by which the judicial branch of Canada supervises decisions made by government bodies like Immigration, Refugees and Citizenship Canada, to ensure they are rendered in accordance with the law and administrative principles. In the context of immigration, the courts will proceed with an analysis of the visa officer’s decision to determine if it is reasonable, and will use a standard set by the Supreme Court of Canada in a landmark case called <em>Canada (Minister of Citizenship and Immigration) v. Vavilov</em>, known as the "Vavilov" decision.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='what_is_the_procedure'>
                                WHAT IS THE PROCEDURE?
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p id='step1'>
                                <span>STEP 1: Initiation of proceedings </span>
                            </p>
                            <p>
                                The process of judicial review is initiated by the filing of an <em>Application for Leave and Judicial Review</em>  with the registry of the Federal Court.  In a legal context, the term "leave" refers to permission or authorization granted by a court. When you request leave, you are asking the court for permission to take a particular action, such as filing an appeal or initiating a legal process.
                            </p>
                            <p>
                                This document must be filed by your lawyers within sixty (60) days of the refusal, for applications arising from outside Canada. This delay is fifteen (15) days if the application is submitted from within Canada. If you are outside of these delays, your lawyers can request an extension with the Court Registry, provided that this exceptional measure is adequately justified. If your application is accepted by the Registry, they will appoint a file number to your case (IMM-X-X) typically within twenty-four (24) hours.
                            </p>
                            <p id='step2'>
                                <span>STEP 2: Determine prospects for judicial review </span>
                            </p>
                            <p>
                                The Application for Leave and Judicial Review contains a request of the officer’s reasons for refusal. The requested document contains the officer’s explanation for refusing your application, and is typically processed by the Federal Court Registry within fourteen (14) days. The reasons are sent by the litigation department of Immigration, Refugees and Citizenship Canada to your lawyers.
                            </p>
                            <p>
                                After receiving the reasons, your legal counsel will assess the chances of success for judicial review by determining whether the decision is justified in light of the facts, the law, and the Federal Court’s case law.
                            </p>
                            <p id='step3'>
                                <span>STEP 3: Application Record</span>

                            </p>
                            <p>
                                If the legal team decide to go forward with your case, they will have to file an Application Record with the Federal Court within thirty (30) days of receiving the reasons. The Application Record Is a compilation of all documents that were before the visa officer during the decision-making process, and a Memorandum of Law and Arguments. This memorandum is drafted by your lawyers, and contains the argumentation which explains why the officer’s decision should be overturned.
                            </p>
                            <p id='step4'>
                                <span>STEP 4: Respondent’s Memorandum and Reply Memorandum</span>
                            </p>
                            <p>
                                The legal counsel of Immigration, Refugees and Citizenship Canada will have to file a Respondent’s Memorandum within thirty (30) days of receiving your Application Record. In this memorandum, they will attempt to reply to your lawyer’s argumentation.
                            </p>
                            <p>
                                After receiving the Respondent’s memorandum, your lawyers will have ten (10) days to respond in a Reply Memorandum.
                            </p>

                            <p id='step5'>
                                <span>STEP 5: Hearing Date</span>
                            </p>
                            <p>
                                At any moment throughout the process, Immigration, Refugees and Citizenship Canada can decide to settle your file and send it back for redetermination by another officer, who will render a new decision.
                            </p>
                            <p>
                                If, however, the file is not settled after the submission of the Reply Memorandum, the Application for Leave and Judicial Review will be pending before the Federal Court. During this time, both parties can expect the presiding judge to issue a Certified Tribunal Records order. This order is an indication that a judge will authorize a hearing for your case.
                            </p>
                            <p>
                                The assigning of a hearing date can take as long as six (6) months. If leave is granted by a judge, a hearing will be granted and scheduled, and the Court will request that the parties try and settle the file within fifteen (15) days of the hearing date. If no settlement is reached, your lawyers, along with the legal counsel of Immigration, Refugees and Citizenship Canada, will appear before the Federal Court for a hearing on your case.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='what_are_the_possible_outcomes'>
                                WHAT ARE THE POSSIBLE OUTCOMES?
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The outcomes of judicial review are varied. The most favorable outcome is that Immigration, Refugees and Citizenship Canada decides to settle your file at any point of the proceedings. If a settlement is reached between the parties, the application will be sent back to a new visa officer for redetermination, and a new decision will be issued. This does not guarantee that your immigration application will be accepted. You will, however, have the opportunity to add updated or new documentation to your application.
                            </p>
                            <p>
                                The Federal Court can also dismiss your Application for Leave and Judicial Review if, after reading the Memorandum of Law and Arguments, the Respondent’s Memorandum and the Reply Memorandum, they determine that there are no merits worthy of a hearing. A dismissal excludes any further appeal.
                            </p>
                            <p>
                                Lastly, if no settlement is reached and the Federal Court authorizes the Application for Leave and Judicial Review, both parties will appear before the Federal Court for a hearing, during which a decision will be rendered on your case. The judge may choose to uphold the refusal or set aside the officer’s decision and send your application back to redetermination to a new visa officer.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='study-service'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">

                            <p>
                                M&C Premier Immigration’s legal counsel is made of experienced Canadian litigators who are comfortable in the courtroom. Our legal team’s experience in challenging refusals extends to a range of programs, including complex cases that require collaboration with Immigration, Refugees and Citizenship Canada. They remain vigilant in monitoring the evolving landscape of Federal Court rulings and always make sure to implement the latest developments in their representation. It is important to have an immigration lawyer in Canada fighting on your behalf, and you can trust that your case is in capable hands at every step of the process.
                            </p>
                            <p>
                                <em>
                                    Book a consultation and let’s discuss strategy!
                                </em>
                            </p>

                            {/* <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/judrev/04.jpg', alt: 'Image 1' },
                                            { src: '../assets/judrev/03.jpg', alt: 'Image 2' },
                                            { src: '../assets/judrev/02.jpg', alt: 'Image 3' },
                                            { src: '../assets/judrev/01.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div> */}


                        </div>
                    </div>





                </div>
            </div>

        </>
    )
}
