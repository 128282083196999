import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function SelfEmployedPerson() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };
    return (

        <>
            <div className="page-hero self">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>ENTREPRENEUR OR SELF-EMPLOYED INDIVIDUALS</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The Entrepreneur or Self-Employed Individuals work permit is designed for individuals aspiring to establish or manage a business in Canada.
                            </p>
                        </div>
                    </div>
                </div>
            </div>




            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#candidates" onClick={(e) => { e.preventDefault(); scrollToSection('candidates'); }}>
                                    CANDIDATES
                                </a>
                            </li>
                            <li >
                                <a href="#general-requirments" onClick={(e) => { e.preventDefault(); scrollToSection('general-requirments'); }}>
                                    GENERAL REQUIREMENTS
                                </a>
                            </li>
                            <li>
                                <a href="#spin-off" onClick={(e) => { e.preventDefault(); scrollToSection('spin-off'); }}>
                                    SPIN OFF BENEFITS
                                </a>
                            </li>
                            <li>
                                <a href="#funds" onClick={(e) => { e.preventDefault(); scrollToSection('funds'); }}>
                                    FUNDS
                                </a>
                            </li>
                            <li>
                                <a href="#duration" onClick={(e) => { e.preventDefault(); scrollToSection('duration'); }}>
                                    DURATION
                                </a>
                            </li>
                            <li>
                                <a href="#primary-residence" onClick={(e) => { e.preventDefault(); scrollToSection('primary-residence'); }}>
                                    PERMANENT RESIDENCE PATHWAY
                                </a>
                            </li>
                            <li>
                                <a href="#servises" onClick={(e) => { e.preventDefault(); scrollToSection('servises'); }}>
                                    OUR SERVICES
                                </a>
                            </li>


                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="candidates">
                            <h1>
                                CANDIDATES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The Government of Canada defines candidates for this work permit as follows :
                            </p>
                            <p>
                                <span>SELF-EMPLOYED </span>A person who works for themselves as the owner of a business and rarely hires people outside of their family members. While many individuals are the owners of the business, they typically carry out all the responsibilities of an employee.
                            </p>
                            <p>
                                <span>ENTREPRENEUR </span>A person who organizes and operates a business or businesses, taking on greater than normal financial risks to do so. They hire employees other than their family members to carry out the activities of the business.
                            </p>
                            <p>
                                Under this program, the applicant is both the <b>employer</b> and the <b>employee </b>and must meet the requirements for <b>both </b>roles.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='general-requirments'>
                                GENERAL REQUIREMENTS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                What distinguishes this program from other LMIA-exempt work permits is that applicants must <b>demonstrate that they have the skills, background and experience</b> to set up a successful business in Canada, or take over an existing business and manage it successfully.
                            </p>
                            <p>
                                In addition, candidates must :
                            </p>
                            <p>
                                <ul>
                                    <li>establish themselves in Canada </li>
                                    <li>demonstrate ownership of at least <b>fifty (50) percent of shares in their business</b></li>
                                    <li>issue themselves an offer of employment respecting the requirements set forth by Immigration, Refugees, and Citizenship Canada </li>
                                    <li>establish that their business activity will not be detrimental to the interests of Canadian service providers.</li>
                                    <li>demonstrate the temporary nature of their stay</li>
                                    <li>prove that their business activities in Canada will result in <b> economic, social or cultural benefits or opportunities</b> for Canadian citizens or permanent residents</li>
                                </ul>
                            </p>
                            <p>
                                Apart from the challenges associated with establishing a start-up or taking over an existing business in Canada, foreign entrepreneurs must very carefully consider the requirement of demonstrating significant benefits of their business, often regarded as the most strategic aspect of the application process.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='spin-off'>
                                SPIN OFF BENEFITS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Under this program, officers have the authority to evaluate any additional benefit from self-employment when making their decision. At M&C Premier Immigration, we make sure to address all these elements when crafting the business plan for your work permit application – including but is not limited to:
                            </p>
                            <p>
                                <ul>
                                    <li>is the business likely to create jobs in the area?</li>
                                    <li>is the business likely to provide economic stimulus to the area?</li>
                                    <li>does the applicant have the language abilities to operate the business?</li>
                                    <li>does the applicant have a particular background or skills that will improve the viability of the business?</li>
                                    <li>has the applicant taken steps to initiate their business?</li>
                                    <li>has the applicant put the business plan into action?</li>
                                    <li>does the applicant have the financial ability to begin the business?</li>
                                    <li>does the applicant have the financial ability to pay expenditure?</li>
                                    <li>can the applicant rent a space?</li>
                                    <li>does the applicant have a realistic staffing plan?</li>
                                    <li>does the applicant have a business number?</li>
                                    <li>does the applicant have ownership documents or agreements?  </li>
                                    <li>is the business of a temporary nature?</li>
                                    <li>is the foreign national establishing a long-term business that will require their presence indeterminately?</li>
                                </ul>
                            </p>
                            <p>
                                The success of this work permit depends on the effective presentation of your venture, and the demonstration that all requirements of the program have been fulfilled. The business plan must outline the significant benefits of the business, as defined in immigration regulations, and provide sufficient information of your background and skills as a self-employed person or entrepreneur.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='funds'>
                                FUNDS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The guidelines of this work permit do not provide for a minimum financial requirements. However, candidates must be able to demonstrate financial capacity to begin operations in Canada. The amount of funds presented will depend on your business plan, initial capital investment and financial projections. We suggest that you have sufficient financial resources to invest in your venture for the first two (2) years before your business starts generating solid returns.
                            </p>
                            <p>
                                If your budget is between $250,000 and $500,000 CAD and you can tolerate a moderate amount of risk, we suggest that you explore buying a franchise or a non-franchised business.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='duration'>
                                DURATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The initial work permit is usually issued for two (2) years and can be extended several times, if the business in Canada is active and profitable.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='primary-residence'>
                                PERMANENT RESIDENCE PATHWAY
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                After one year of work experience, candidates become eligible for an Express Entry application for permanent residence. Once you become a permanent resident, you can continue working as an employee or a business owner.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='servises'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                This program is not a one-size-fits-all deal; and candidates must make strategic decisions in the establishment of their business to convince the officer of their intention to begin operations in Canada – all while minimizing expenditures. That's why having an expert lawyer by your side is crucial.
                            </p>
                            <p>
                                <ul className='special'>
                                    <li>
                                        <em><span>BUSINESS PLAN  </span></em> Our expert lawyers work closely with you to craft a detailed business plan that highlights the unique advantages of your venture, ensuring alignment with immigration requirements and business objectives.
                                    </li>
                                    <li>
                                        <em><span> MARKET RESEARCH  </span></em> M&C Premier Immigration will assist you with conducting thorough market research to identify opportunities, assess competition, and understand consumer preferences, providing valuable insights to inform your business strategy and immigration application.
                                    </li>
                                    <li>
                                        <em><span> EXECUTION PLAN  </span></em> Our lawyers assist in developing a comprehensive execution plan that outlines actionable steps to achieve your business goals, ensuring clarity, feasibility, and compliance with immigration regulations.
                                    </li>
                                    <li>
                                        <em><span>   BUSINESS ESTABLISHMENT IN CANADA </span></em> Our lawyers provide comprehensive legal support to navigate the complexities of establishing your business in Canada, including incorporation, licensing, permits, and compliance with regulatory frameworks.
                                    </li>
                                    <li>
                                        <em><span>   MARKETING STRATEGY </span></em> Leveraging our legal and business expertise, we offer strategic guidance to help you make informed decisions at every stage of launching your business in Canada, ensuring optimal outcomes for both your business and immigration goals.
                                    </li>
                                    <li>
                                        <em><span>   EMPLOYMENT OFFER </span></em> Our experienced lawyers draft precise and legally compliant job offers tailored to meet immigration requirements, ensuring clarity, accuracy, and alignment with Canadian labor laws.
                                    </li>
                                    <li>
                                        <em><span>   WORK PERMIT APPLICATION </span></em> We guide you through the process of applying for a work permit, providing support to compile required documentation, navigate application procedures, and address any legal complexities to facilitate a successful application.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                <em>Book and consultation with us to find out if you’re eligible!</em>
                            </p>
                            <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/selfbusiness/04.jpg', alt: 'Image 1' },
                                            { src: '../assets/selfbusiness/03.jpg', alt: 'Image 2' },
                                            { src: '../assets/selfbusiness/01.jpg', alt: 'Image 3' },
                                            { src: '../assets/selfbusiness/02.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>





                </div>
            </div>

        </>
    )
}
