import '../Css/Clints.css';
import React, { useRef } from 'react'
import Slider from 'react-slick'
import ClientSlider from './ClientSlider.js';
import { motion } from "framer-motion";

let clients = [
    {
        name: "Aarush Gadhe",
        stars: 5,
        disc1: "Myriam and Basma worked as a team to ensure our application was reviewed and submitted timely despite the unique dual intent case. Am so grateful for having had them as manager of my family's case, they were understanding and they guided us through the entire process. They gave us hope and after more than a year of waiting the application was successfully closed and we landed in Canada as one happy family.",
        
    },
    {
        name: " Saleh Abou Jalad",
        stars: 4,
        disc: "Exceptionally nice, fantastic and experienced group of people. It was a great pleasure working with Basma for the process to get Permanent Residence in Canada for me and my wife. All communications was clear and to the point, and she handled the application with great care. I would recommend MC Premier Immigration to anyone looking for experienced and exceptional people to take care of their immigration needs."
    },
    {
        name: "Fadi Ghantous",
        stars: 4,
        disc: "Myriam helped me incorporate my business in BC, Canada for my work permit application. I already did the application but the team reviewed the entire documents and did not charge extra fees for recommendations even if there were plenty. They made recommendations for my business plan and explained to me in details. The team also helped me follow up with IRCC. I recommend them to my colleagues in the industry."
    },
    {
        name: "Sarah Luan Tran",
        stars: 5,
        disc: "Thank you for your professional work. They helped me with the successful acquisition of my Permanent Residence very quickly and efficiently. They are very knowledgeable, and they were always available when I had questions. Special thanks to Basma. Excellent law firm and highly recommended."
    },
    {
        name: " Faisal Hamidi",
        stars: 4,
        disc: "The Moorcroft Chazbek firm is the best! With their help my wife was able to get her pr in like three months! They are very patient and always hard at work. They also go out of their way for you. Thank you Myriam and Basma, and the rest of the team! Thank you all so much for making our dream possible."
    },
    {
        name: "Ahmed Abdallah",
        stars: 5,
        disc: "After my work permit application was rejected by ircc, I was referred to the Moorcroft & Chazbek firm by my consultant. Basma recommended doing a judicial review to remove the refusal. Basma was able to settle my case with the lawyers of ircc and my work permit was returned for a second decision and accepted after 3 months. Hiring Basma was a fantastic decision. Many thanks to the entire team for their assistance."
    },
    {
        name: "Arisha Sharma",
        stars: 5,
        disc: "Myriam and basma helped me with my study permit application and they help me write my study plan for the immigration officer. My study permit was approved right before the beginning of classes thanks to their follow up with IRCC. They also recommended me to get a deferral from university just in case but it wasnt needed. I’m very satisfied with their services. Thank you to the team."
    },
    {
        name: "Mohamed Badawi",
        stars: 4,
        disc: "As a business owner based in Dubai, I was exploring options for permanent residence in Canada. The legal team provided a detailed overview of how I could start my operations in Canada as a business owner. I am glad with my decision to choose them and they offered the support and guidance that I was lacking from previous firms I had consulted."
    },
]
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
}

const Clints = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i} />
    ))
    return (
        <div className="clints-part">
            <div className='clints' id='client'>
                <h1
                    initial={{ opacity: 0, y: 10 }}
                    whileInView={{ opacity: 1, y: 0, transition: { delay: 0.2, duration: 0.5 } }}
                    viewport={{ once: false, amount: 0.1 }}
                >
                   Testimonials
                </h1>
                <hr />
                <div className='testimonials'>
                    <Slider ref={arrowRef} {...settings}>
                        {clientDisc}
                    </Slider>
                </div>
            </div>

        </div>
    )
}

export default Clints;



