import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import '../Css/Faq.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            const data = [
                {
                    question: 'CANADA',
                    answer: <>
                        <h1>
                            1. Travel Visa Approvals:
                        </h1>
                        <p>
                            <ul>
                                <li>
                                    <strong>Q: </strong>How do I apply for a visitor visa to Canada?
                                </li>
                                <hr />
                                <li>
                                    <strong>A: </strong> You can apply for a visitor visa online or through a Visa Application Centre (VAC) in your country. You will need to submit the required documents, including your passport, proof of funds, and a letter of invitation if applicable.
                                </li>
                            </ul>
                        </p>


                        <h1>
                            2. Study Visas:
                        </h1>
                        <p>
                            <ul>
                                <li>
                                    <strong>Q: </strong> What are the requirements for obtaining a study permit in Canada?
                                </li>
                                <hr />
                                <li>
                                    <strong>A: </strong> To obtain a study permit, you must have an acceptance letter from a designated learning institution in Canada, prove that you have enough money to cover tuition fees and living expenses, and satisfy other immigration requirements.
                                </li>
                            </ul>
                        </p>

                        <h1>
                            3. Work Permits:
                        </h1>
                        <p>
                            <ul>
                                <li>
                                    <strong>Q: </strong>How can I apply for a work permit in Canada?
                                </li>
                                <hr />
                                <li>
                                    <strong>A: </strong>You can apply for a work permit either online or through a VAC. You will need a job offer from a Canadian employer and may need to provide proof of your qualifications and work experience.
                                </li>
                            </ul>
                        </p>

                        <h1>
                            4. Temporary Residency:
                        </h1>
                        <p>
                            <ul>
                                <li>
                                    <strong>Q: </strong> How long can I stay in Canada on a temporary visa?
                                </li>
                                <hr />
                                <li>
                                    <strong>A: </strong>The duration of stay depends on the type of visa you have. Visitor visas typically allow stays of up to six months, while study and work permits are issued for the duration of your study or employment.
                                </li>
                            </ul>
                        </p>

                        <h1>
                            5. Permanent Residency and Citizenship:
                        </h1>
                        <p>
                            <ul>
                                <li>
                                    <strong>Q: </strong> How can I apply for permanent residency in Canada?
                                </li>
                                <hr />
                                <li>
                                    <strong>A: </strong>You can apply for permanent residency through various immigration programs such as Express Entry, Provincial Nominee Programs (PNPs), Family Sponsorship, or as a refugee. Citizenship can be obtained after fulfilling residency requirements.
                                </li>
                            </ul>
                        </p>
                    </>,
                },
                { question: 'UNITED STATES', answer:  <>
                <h1>
                    1. Travel Visa Approvals:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong> How do I apply for a tourist visa to the United States?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong> The visitor visa, also known as a tourist visa, is a type of nonimmigrant visa for people who wish to temporarily enter the U.S. There are two categories: B-1 for business travel and B-2 for tourism and medical treatment. You can apply for a tourist visa (B-2 visa) at the nearest U.S. embassy or consulate in your country. You will need to complete the online application form DS-160 and attend a visa interview.
                        </li>
                    </ul>
                </p>


                <h1>
                    2. Study Visas:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>  What is the process for obtaining an F-1 student visa for the United States?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong> To obtain an F-1 student visa, you must first be accepted by a Student and Exchange Visitor Program (SEVP)-approved school in the United States. You will then need to complete the Form I-20 and attend a visa interview.
                        </li>
                    </ul>
                </p>

                <h1>
                    3. Work Permits:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How can I get a work permit in the United States?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong> Work permits in the United States are typically tied to specific employment-based visas, such as the H-1B visa for specialty occupations or the L-1 visa for intracompany transfers.
                        </li>
                    </ul>
                </p>

                <h1>
                    4. Temporary Residency:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How long can I stay in the United States on a tourist visa?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>Tourist visas typically allow stays of up to six months. Extensions may be possible in certain circumstances.
                        </li>
                    </ul>
                </p>

                <h1>
                    5.Permanent Residency and Citizenship:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How do I apply for a green card (permanent residency) in the United States?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>You can apply for a green card through family sponsorship, employment, refugee or asylum status, or through the Diversity Visa Lottery program. Citizenship can be obtained after meeting residency requirements.
                        </li>
                    </ul>
                </p>
            </>,
            
            
            },
                { question: 'UNITED KINGDOM', answer:  <>
                <h1>
                    1. Travel Visa Approvals:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How can I apply for a tourist visa to the United Kingdom?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong> You can apply for a Standard Visitor visa online through the UK government website or at a Visa Application Centre (VAC) in your country.
                        </li>
                    </ul>
                </p>


                <h1>
                    2. Study Visas:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>What are the requirements for obtaining a Tier 4 student visa for the United Kingdom?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>You must have an unconditional offer from a licensed Tier 4 sponsor, sufficient funds to cover your tuition fees and living expenses and meet English language requirements.
                        </li>
                    </ul>
                </p>

                <h1>
                    3. Work Permits:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How can I obtain a work permit (Tier 2 visa) for the United Kingdom?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>You must have a job offer from a UK employer with a valid Certificate of Sponsorship (CoS) and meet the required skill and salary thresholds.
                        </li>
                    </ul>
                </p>

                <h1>
                    4. Temporary Residency:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How long can I stay in the UK on a Tier 2 work visa?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>Tier 2 work visas are typically granted for the duration of the employment contract, up to a maximum of five years.
                        </li>
                    </ul>
                </p>

                <h1>
                    5.Permanent Residency and Citizenship:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How can I apply for permanent residency (indefinite leave to remain) in the United Kingdom?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>You may be eligible to apply for indefinite leave to remain after residing in the UK for a specified period under certain visa categories. Citizenship can be obtained after meeting residency requirements.
                        </li>
                    </ul>
                </p>
            </>,
            
            },
                { question: 'UNITED ARAB EMIRATES', answer: <>
                <h1>
                    1. Travel Visa Approvals:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How can I obtain a tourist visa for the United Arab Emirates?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>Tourist visas for the UAE can be obtained through airlines, hotels, travel agencies, or online through the UAE’s eVisa system.
                        </li>
                    </ul>
                </p>


                <h1>
                    2. Study Visas:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>What is the process for obtaining a student visa for the United Arab Emirates?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>Student visas are typically sponsored by educational institutions in the UAE. You will need to apply through your institution and fulfill the necessary requirements.
                        </li>
                    </ul>
                </p>

                <h1>
                    3. Work Permits:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How can I get a work permit (employment visa) in the United Arab Emirates?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong> Employment visas are typically sponsored by employers in the UAE. You will need a job offer and sponsorship from a UAE-based employer to obtain a work permit.
                        </li>
                    </ul>
                </p>

                <h1>
                    4. Temporary Residency:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>How long can I stay in the UAE on a tourist visa?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>Tourist visas in the UAE typically allow stays of up to 30 or 90 days, depending on the type of visa and nationality.
                            </li>
                    </ul>
                </p>

                <h1>
                    5.Permanent Residency and Citizenship:
                </h1>
                <p>
                    <ul>
                        <li>
                            <strong>Q: </strong>Can foreigners obtain permanent residency in the United Arab Emirates?
                        </li>
                        <hr />
                        <li>
                            <strong>A: </strong>The UAE offers long-term residency visas for investors, entrepreneurs, talented individuals, and outstanding students. Citizenship is not typically available to foreigners in the UAE.
                        </li>
                    </ul>
                </p> 
                </>}
            ];
            setFaqData(data);
            setIsLoading(false);
        }, 500);
    }, []);


    useEffect(() => {
        if (!isLoading) {
            setActiveIndex(0);
        }
    }, [isLoading]);

    const toggleAccordion = index => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <>
            <div className="page-hero faq">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>FAQ's</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='faq-page'>
                {isLoading ? (
                    ""
                ) : (
                    <div className='main'>
                        {faqData.map((item, index) => (
                            <div key={index} className="accordion-item">
                                <div
                                    className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    <h2>{item.question}</h2>
                                    {activeIndex === index ? (
                                        <FontAwesomeIcon icon={faMinus} className='icon' />
                                    ) : (
                                        <FontAwesomeIcon icon={faPlus} className='icon' />
                                    )}

                                </div>
                                {activeIndex === index && (
                                    <div className="accordion-content">
                                        <hr />
                                        <p>{item.answer}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default FAQ;
