import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function Plocy() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };



    return (
        <>
            {/* Hero */}
            {/* Hero */}
            {/* Hero */}
            <div className="page-hero policy">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>PRIVACY POLICY</h1>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    PRELIMINARY
                                </a>
                            </li>
                            <li >
                                <a href="#personal_infromation" onClick={(e) => { e.preventDefault(); scrollToSection('personal_infromation'); }}>
                                    PERSONAL INFORMATION
                                </a>
                            </li>
                            <li>
                                <a href="#third_party" onClick={(e) => { e.preventDefault(); scrollToSection('third_party'); }}>
                                    PERSONAL INFORMATION OF THIRD-PARTY
                                </a>
                            </li>
                            <li>
                                <a href="#a_minor" onClick={(e) => { e.preventDefault(); scrollToSection('a_minor'); }}>
                                    PERSONAL INFORMATION OF A MINOR
                                </a>
                            </li>
                            <li>
                                <a href="#collection_of_personal_infromation" onClick={(e) => { e.preventDefault(); scrollToSection('collection_of_personal_infromation'); }}>
                                    COLLECTION OF PERSONAL INFORMATION
                                </a>
                            </li>
                            <li>
                                <a href="#purpose_of_collection" onClick={(e) => { e.preventDefault(); scrollToSection('purpose_of_collection'); }}>
                                    PURPOSE OF COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION
                                </a>
                            </li>
                            <li>
                                <a href="#opting_out_of_communication" onClick={(e) => { e.preventDefault(); scrollToSection('opting_out_of_communication'); }}>
                                    OPTING OUT OF COMMUNICATIONS
                                </a>
                            </li>
                            <li>
                                <a href="#sharing_your_personal_information" onClick={(e) => { e.preventDefault(); scrollToSection('sharing_your_personal_information'); }}>
                                    SHARING YOUR PERSONAL INFORMATION
                                </a>
                            </li>
                            <li>
                                <a href="#data_flow" onClick={(e) => { e.preventDefault(); scrollToSection('data_flow'); }}>
                                    STORING OF PERSONAL INFORMATION AND TRANS-BORDER DATA-FLOW
                                </a>
                            </li>
                            <li>
                                <a href="#retention_of_personal_information" onClick={(e) => { e.preventDefault(); scrollToSection('retention_of_personal_information'); }}>
                                    RETENTION OF PERSONAL INFORMATION
                                </a>
                            </li>
                            <li>
                                <a href="#sfaeguarding_your_personal" onClick={(e) => { e.preventDefault(); scrollToSection('sfaeguarding_your_personal'); }}>
                                    SAFEGUARDING YOUR PERSONAL INFORMATION
                                </a>
                            </li>
                            <li>
                                <a href="#your_rights" onClick={(e) => { e.preventDefault(); scrollToSection('your_rights'); }}>
                                    YOUR RIGHTS
                                </a>
                            </li>
                            <li>
                                <a href="#update_policy" onClick={(e) => { e.preventDefault(); scrollToSection('update_policy'); }}>
                                    UPDATE OF THIS POLICY
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="two">
                            <h1>
                                PRELIMINARY
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                            At Moorcroft & Chazbek Premier Immigration, your privacy is of utmost importance. As a law firm, we will regularly collect, use, store and disclose information in the course of our services in immigration, whether it be consultancy or litigation. In this context, we care about how your personal information is handled and are committed to maintaining the highest standards of privacy, per the Canadian privacy governance legislation and international privacy standards.
                            </p>
                            <p>
                                When you give us access to your personal information via our website, by email, over the phone or in person, you consent to have your information processed per the present privacy policy – drafted to help our clientele understand how we collect, use, disclose and retain their personal information.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='personal_infromation'>
                                PERSONAL INFORMATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                In the course of our legal services, we will collect a certain amount of personal information about you, including :
                            </p>
                            <p>
                                <ul>
                                    <li>Contact information (i.e. physical address, email address and telephone number);</li>
                                    <li>Biographical information (i.e. employment position, employer, education level, photograph and video or audio content);</li>
                                    <li>Billing and financial information such as billing address, bank account and payment information;</li>
                                    <li>Recruitment information (i.e. curriculum vitae, education and employment history, details of professional memberships and other information relevant to potential recruitment by employers);</li>
                                    <li>Information provided to us by or on behalf of our clients in the course of providing our legal services, which may, where relevant, include special categories of personal information (i.e. as racial or ethnic origin, political opinions, religious beliefs, health or sexual orientation, etc.);</li>
                                    <li>Identification documents and other background verification data (i.e. copy of driver’s licence, passports or utility bills, banking certificates and account statements).</li>
                                    <li>Any other personal information provided or required by the applicable law or for a legitimate purpose.</li>
                                </ul>
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='third_party'>
                                PERSONAL INFORMATION OF THIRD-PARTY
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                If you provide us personal information about other people (i.e. family members, or – in the context of business – directors, officers, shareholders, partners or managers), you are required give those individuals a notice that you are sharing their information to our firm, and have obtained their consent to that disclosure; Where applicable, records of their consent will be required. The record of consent is required to indicate the date, time and means of consent, in circumstances where this consent is needed for the processing of personal information.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='a_minor'>
                                PERSONAL INFORMATION OF A MINOR
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                We do not collect information from children or minors who are under sixteen (16) years old. If you are a parent or a legal guardian and you know that your children have provided us with personal information without your parental or guardian consent, please contact us and we will take steps to remove that information from our database and servers.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='collection_of_personal_infromation'>
                                COLLECTION OF PERSONAL INFORMATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Moorcroft & Chazbek Premier Immigration will collect your personal information in the course of :
                            </p>
                            <p>
                                <ul>
                                    <li>our interactions with you for the provision of our legal services;</li>
                                    <li>when you book a consultation on our website;</li>
                                    <li>when you register to our newsletter.</li>
                                </ul>
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='purpose_of_collection'>
                                PURPOSE OF COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                We use your personal information to provide and improve our services to you and to meet our legal obligations as attorneys. We may use your personal information in the following ways, either with your consent (whether implied or express) or, where applicable, according to other legal bases under <em>The Personal Information Protection and Electronic Documents Act</em>  (PIPEDA).
                            </p>
                            <p>
                                In every case, we identify the legal grounds on which we use your personal information:
                            </p>
                            <p>
                                <ul>
                                    <li>To provide, administer or perform our consultancy services in immigration, business, and corporate law, including our litigation services, to conduct our business operations, to carry out our obligations arising from any agreements entered into between our clientele and our firm.</li>
                                    <li>To respond to requests for information or inquiries from visitors to our websites. </li>
                                    <li>Where an individual has chosen to receive the following: for marketing and business development purposes – to provide details of our services, legal updates and invitations to seminars and events.</li>
                                    <li>For research and development purposes – in order to better understand our clients’ services, to update our legal practices, to better understand the marketing requirements for the development of our business, services and offerings. </li>
                                    <li>For recruitment purposes – to enable us to process employment applications and assess your suitability for any position for which you may apply at Moorcroft & Chazbek Premier Immigration.</li>
                                    <li>To fulfil our legal, regulatory, or risk management obligations and comply with our legal obligations (performing client due diligence, identifying conflicts of interest). </li>
                                    <li>To enforce our legal rights, to comply with our legal or regulatory reporting obligations and/or to protect the rights of third parties.</li>
                                    <li>To ensure that we are paid – to recover any payments due to us and where necessary to enforce such recovery through the engagement of debt collection agencies or taking other legal action (including the commencement and carrying out of legal and court proceedings).</li>
                                    <li>To fight against fraud in the provision of services, as may be required by applicable law and regulation and/or best practice. Where fraud is identified, details may be passed to fraud prevention agencies and recorded by them.</li>
                                    <li>In the event that we merge, combine or divest a part of our business, we may need to transfer some or all of your personal information to the relevant third party or their advisors, as part of any due diligence process, for the same purposes as set out in this Privacy Policy or to analyze any potential reorganization of our firm.</li>
                                </ul>
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='opting_out_of_communication'>
                                OPTING OUT OF COMMUNICATIONS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                If you no longer want to receive our newsletter, you can opt-out of receiving such emails by clicking the “unsubscribe” link at the bottom of any email you receive from us, or by contacting us directly using the contact information in the “Contact Us” section below. We aim to respond to your opt-out request promptly, but we ask that you please allow us a maximum of ten (10) days to process your request. Please note that, as a client, even if you opt-out from receiving our newsletter, we may still need to send you communications about your use of our services, or other matters.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='sharing_your_personal_information'>
                                SHARING YOUR PERSONAL INFORMATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                In the course of the provision of our services and business operations, Moorcroft & Chazbek Premier Immigration may also share personal information with certain third parties such as:
                            </p>
                            <p>
                                <ul>
                                    <li>Third-party service providers and vendors who provide website, maintenance, hosting, application development, and other technological services to us, including human resources management and marketing activities. They may have access to, or process personal information as part of providing those services for us – but we limit the information provided to that which is reasonably necessary for them to perform their functions, and our contracts with them require them to maintain the confidentiality of such information;</li>
                                    <li>Governmental entities or law enforcement when required by law, or in the good-faith belief that such disclosure is necessary to abide by applicable laws. This includes disclosure in response to a court order, judicial or other government subpoena or warrant, or to otherwise cooperate with law enforcement and other governmental agencies;</li>
                                    <li>An acquirer, successor or assignee as part of any merger, acquisition, debt financing, sale of assets, or similar transaction, as well as in the event of an insolvency, bankruptcy, or receivership in which information is transferred to one or more third parties as one of our business assets;</li>
                                </ul>
                            </p>
                            <p>
                                We will only use personal information to fulfil the primary purpose and applicable legitimate purpose it was collected for, or for a purpose compatible with that primary purpose.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='data_flow'>
                                STORING OF PERSONAL INFORMATION AND TRANS-BORDER DATA FLOW
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Your personal information is currently hosted in Canada and the United Arab Emirates. As a global law firm, your personal information may be stored and processed in any country where we have facilities or in which we engage third-party service providers (i.e. Canada, United Arab Emirates, Qatar, Saudi Arabia). When your personal information is outside of your country of residence, it will be subject to the laws of the country in which it is held, and it may be subject to disclosure to the governments, courts or law enforcement or regulatory agencies, pursuant to the laws of that country. When you share your personal information with our firm, you consent to the transfer of information to countries outside your country of residence, which may have different data protection rules than in your country of residence.
                            </p>
                            <p>
                                In any case, our personal information governance practices will continue to be governed by this Privacy Policy and we will comply with, where applicable, the General Data Protection Regulation (GDPR), providing adequate protection for the transfer of personal information from the GCC/Canada to third country, by implementing appropriate safeguards such as standard contractual clauses. Moorcroft & Chazbek Premier Immigration will hold organizations outside Canada accountable under Canadian law for any personal information which may be transferred to them.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='retention_of_personal_information'>
                                RETENTION OF PERSONAL INFORMATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Your personal information will be kept for as long as reasonably necessary in order for Moorcroft & Chazbek Premier Immigration to render to completion their consultancy or litigation services, fulfill the purposes set out in this Privacy Policy, or comply with our legal and regulatory obligations. If you want to further inquire about the periods during which your personal information is stored, you can contact us through the “Contact Us” section.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='sfaeguarding_your_personal'>
                                SAFEGUARDING YOUR PERSONAL INFORMATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                We follow generally accepted privacy principles (GAPPs) to protect the information we receive from our clientele and maintain appropriate technical, administrative and physical safeguards to protect personal information against any accidental loss, unauthorized modification, unauthorized disclosure or access, misuse, accidental or unlawful destruction, and any other unlawful form of processing of the personal information in our servers. Moorcroft & Chazbek Premier Immigration has implemented measures to restrict access to your personal information only to authorized personnel who have a legitimate business need or whose responsibilities necessitate such access, and who are bound by confidentiality obligations.
                            </p>
                            <p>
                                While we employ various security measures, it's important to note that no method of transmitting or storing data online is entirely foolproof. Therefore, we cannot guarantee the absolute security of any information you share with us. By providing information, you acknowledge and accept this risk. Despite our best efforts, there remains a possibility that information could be accessed, disclosed, altered, or compromised due to breaches in our physical, technical, or administrative safeguards. If you suspect that your personal information has been compromised, please refer to the "Contact Us?" for assistance.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='your_rights'>
                                YOUR RIGHTS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                In accordance with relevant data protection laws, individuals have the following rights regarding their personal information, under specific circumstances:
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        <span>RIGHT TO ACCESS </span>You are entitled to ask us if we are processing your personal information and, if we are, you have the right to request access to that personal information. Subject to applicable law, this will allow you to receive a copy of the personal information we hold and certain other information about you.
                                    </li>
                                    <li>
                                        <span>RIGHT TO ACCURACY </span>When collecting your personal information, we are required to take reasonable steps to ensure that it is accurate, complete, not misleading and up to date;
                                    </li>
                                    <li>
                                        <span>RIGHT TO CORRECTION </span>You are entitled to request that any incomplete or inaccurate personal information we hold be corrected.
                                    </li>
                                    <li>
                                        <span>RIGHT TO ERASURE </span>You are entitled to ask us to delete, destroy or remove personal information from our servers in certain circumstances. We may, however, refuse a request for erasure or destruction in exceptional circumstances, for example, where the personal information is required to comply with law or in connection with claims or required by a contractual agreement between the parties;
                                    </li>
                                    <li>
                                        <span>RIGHT TO OBJECTION </span>You have the right to challenge our processing of your personal information when it's based on legitimate interests (either ours or those of a third party), or for specific direct marketing purposes. However, we may still have the right to continue processing your information.
                                    </li>
                                    <li>
                                        <span>WITHDRAWAL OF CONSENT </span>You are entitled to withdraw your consent to our disclosure or use of your personal information.
                                    </li>
                                    <li>
                                        <span>RIGHT TO INDIVIDUAL ACCESS </span>You can request to be granted access to your personal information, in order to be informed of our collection, use, and disclosure of that personal information. You will have the ability to contest the accuracy and entirety of the information and request amendments as deemed necessary.
                                    </li>
                                    <li>
                                        <span>RIGHT TO CHALLENGE COMPLIANCE </span>You have a right to file a complaint with data protection regulatory authorities in the country/province/state where you normally reside, where we are located, or where an alleged violation of data protection law has taken place.
                                    </li>

                                </ul>
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='update_policy'>
                                UPDATE OF THIS POLICY
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                We reserve the right to modify the content of our websites and our use of cookies without prior notice. As a result, our Privacy Policy and Cookies Policy may be subject to change periodically in the future. Therefore, we recommend that you review them each time you visit our website to stay updated on how we handle personal information.
                            </p>
                            <p>
                                This Privacy Policy was last updated April 1st, 2024.
                            </p>
                        </div>

                      

                            {/* <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/studyincanada/04.jpg', alt: 'Image 1' },
                                            { src: '../assets/studyincanada/03.jpg', alt: 'Image 2' },
                                            { src: '../assets/studyincanada/02.jpg', alt: 'Image 3' },
                                            { src: '../assets/studyincanada/01.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div> */}


                    </div>





                </div>
            </div>



        </>
    )
}

