import React from 'react'
import styled from 'styled-components'
import { IoIosQuote } from "react-icons/io";
import { AiOutlineStar } from "react-icons/ai";
import '../Css/ClientSlider.css';

const ClientSlider = (props) => {
    const { name, stars, disc, disc1, disc2, disc3 } = props.item;
    return (
        <div className='container-1'>
            <div className="part">
                    <div className="name">
                        <h1>{name}</h1>
                    </div>
                <div className='main'>
                    <div>
                        {Array(stars).fill().map((_, i) => (
                            <span className='star' key={i}>
                                <AiOutlineStar />
                            </span>
                        ))}
                    </div>
                </div>

                <div className='dis'>
                    <IoIosQuote className='quto' />
                    <p>
                        {disc}
                    </p>
                    <p>
                        {disc1}
                    </p>
                    <p>
                        {disc2}
                    </p>
                    <p>
                        {disc3}
                    </p>
                    <IoIosQuote className='quto down' />
                </div>


            </div>
        </div>
    )
}

export default ClientSlider


