import '../Css/About.css';
import React from 'react'

export default function About() {
    return (
        <>
                <div className="content-about">
                    <div className="main">
                        <div className="heading">
                            <h1
                            >
                                ABOUT US
                            </h1>
                            <hr />
                        </div>

                        <p>
                            Moorcroft & Chazbek Premier Immigration is a boutique law firm established under the guidance of Canadian attorneys. With our wealth of experience in Canadian immigration law, our team is equipped to address your unique immigration challenges at every juncture of the process, regardless of how complicated they are. Our commitment to excellence means we go beyond navigating the complexities of immigration law – we pave the way for a smooth transition to life in Canada, ensuring every opportunity awaits you. Let us assist you on this new chapter with confidence, clarity, and ease.
                            <br />
                            <br />
                            Our firm serves clients from a wide range of countries, including: <em>Portugal, Spain, France, Greece, Italy, Albania, Armenia, Lebanon, Syria, Palestine, Jordan, Egypt, Iraq, Iran, Morocco, Algeria, Tunisia, India, Pakistan, Bangladesh, Sri Lanka, Qatar, United Arab Emirates, Saudi Arabia, Bahrain, Kuwait, Oman, Russia, Singapore, Vietnam, Thailand, Cambodia, Kenya, Nigeria, Cameroon, Ivory Coast, Mexico, Colombia, Argentina, Chile, Brazil, Venezuela, Austria, Germany, Burundi, Australia, New Zealand, and more.</em>
                        </p>
                    </div>
                </div>
        </>
    )
}
