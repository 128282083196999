import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home.js';
import Header from '../Components/Header.js';
import Footer from '../Components/Footer.js'
import Book from '../Components/Book.js'
import ScrollToTop from '../Components/ScrollToTap.js';

import VisiteCanada from '../Pages/VisiteCanada.js';
import StudyInCanada from '../Pages/StudyInCanada.js';
import WorkInCanada from '../Pages/WorkInCanada.js';
import LmiaExemptWorkPermit from '../Pages/LmiaExemptWorkPermit.js';
import StartUpVisaProgram from '../Pages/StartUpVisaProgram.js';
import EntreOrSelfemployedIndividuals from '../Pages/EntreOrSelfemployedIndividuals.js';
import IntraComapny from '../Pages/IntraComapny.js';
import ExpressEntry from '../Pages/ExpressEntry.js';
import ProvicialNomineeProgram from '../Pages/ProvicialNomineeProgram.js';
import AtlanticImmigrationProgram from '../Pages/AtlanticImmigrationProgram.js';
import StratUpVisaPermanentResidence from '../Pages/StratUpVisaPermanentResidence.js';
import SelfEmployedPerson from '../Pages/SelfEmployedPerson.js';
import JudicalReview from '../Pages/JudicalReview.js';
import ReconsideraRequest from '../Pages/ReconsideraRequest.js';
import Misrepresentation from '../Pages/Misrepresentation.js';
import Blogs from '../Pages/Blogs.js';
import Blog from '../Pages/Blog.js';
import Faq from '../Pages/Faq.js';
import Team from '../Pages/Team.js';
import Plocy from '../Pages/Plocy.js';



export default function RoutesFunction() {
    return (
        <Router>
            <ScrollToTop/>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/visit-canada' element={<VisiteCanada />} />
                <Route path='/study-in-canada' element={<StudyInCanada />} />
                <Route path='/work-in-canada' element={<WorkInCanada />} />
                <Route path='/lmia-exempt-work-permit' element={<LmiaExemptWorkPermit />} />
                <Route path='/start-up-visa-program' element={<StartUpVisaProgram />} />
                <Route path='/entreprenur-or-self-employed-individuals' element={<EntreOrSelfemployedIndividuals />} />
                <Route path='/intra-company-transfer' element={<IntraComapny />} />
                <Route path='/express-entry' element={<ExpressEntry />} />
                <Route path='/provicial-nominee-program' element={<ProvicialNomineeProgram />} />
                <Route path='/atlantic-immigration-program' element={<AtlanticImmigrationProgram />} />
                <Route path='/start-up-visa-permanent-residence' element={<StratUpVisaPermanentResidence />} />
                <Route path='/self-employed-person-permanent-residence' element={<SelfEmployedPerson />} />
                <Route path='/judicial-review' element={<JudicalReview />} />
                <Route path='/reconsidera-request' element={<ReconsideraRequest />} />
                <Route path='/misrepresentation' element={<Misrepresentation />} />
                <Route path='/privacy-policy' element={<Plocy />} />

                <Route path='/blogs' element={<Blogs />} />
                <Route path="/blog/:id" element={<Blog />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/team' element={<Team />} />


            </Routes>
            <Book />
            <Footer />
        </Router>
    )
}