import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function Misrepresentation() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };


    return (
        <>
            <div className="page-hero misrep">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>MISREPRESENTATION</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    WHAT IS MISREPRESENTATION?
                                </a>
                            </li>
                            <li >
                                <a href="#consequence" onClick={(e) => { e.preventDefault(); scrollToSection('consequence'); }}>
                                    CONSEQUENCES
                                </a>
                            </li>
                            <li>
                                <a href="#what_is_the_procedure" onClick={(e) => { e.preventDefault(); scrollToSection('what_is_the_procedure'); }}>
                                    WHAT IS THE PROCEDURE?
                                </a>
                            </li>
                            <li>
                                <a href="#step1" onClick={(e) => { e.preventDefault(); scrollToSection('step1'); }}>
                                    STEP 1: Procedural Fairness Letter
                                </a>
                            </li>
                            <li>
                                <a href="#step2" onClick={(e) => { e.preventDefault(); scrollToSection('step2'); }}>
                                    STEP 2: Response
                                </a>
                            </li>
                            <li>
                                <a href="#step3" onClick={(e) => { e.preventDefault(); scrollToSection('step3'); }}>
                                    STEP 3: Decision
                                </a>
                            </li>
                            <li>
                                <a href="#step4" onClick={(e) => { e.preventDefault(); scrollToSection('step4'); }}>
                                    STEP 4: Judicial Review
                                </a>
                            </li>
                            <li>
                                <a href="#what_hire_a_lawyer" onClick={(e) => { e.preventDefault(); scrollToSection('what_hire_a_lawyer'); }}>
                                    WHY HIRE A LAWYER?
                                </a>
                            </li>
                            <li>
                                <a href="#service" onClick={(e) => { e.preventDefault(); scrollToSection('service'); }}>
                                    OUR SERVICES
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="two">
                            <h1>
                                WHAT IS MISREPRESENTATION?
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The act of misrepresentation is a statutory notion under section 40 of the Immigration and Refugee Protection <em>Act</em> (“Act”), and is an offence assessment per Immigration, Refugees and Citizenship Canada. It may lead to an inadmissibility ban for a permanent resident, or a foreign national.
                            </p>
                            <p>
                                Fraud and misrepresentation are done intentionally and can include, but is not limited to:
                            </p>
                            <p>
                                <ul>
                                    <li>providing incorrect or false information while interacting with an officer</li>
                                    <li>providing incorrect or false information in an immigration application</li>
                                    <li>deliberately holding back information which can make you inadmissible to Canada (even information which has not been asked directly)</li>
                                    <li>providing altered or falsified :
                                        <ul>
                                            <li>travel documents</li>
                                            <li>educational documents, such as diplomas, degrees, transcripts, credential evaluations, apprenticeship documents</li>
                                            <li>certificates of birth, marriage, separation, divorce or death</li>
                                            <li>certificates of police clearance</li>
                                        </ul>
                                    </li>

                                </ul>
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='consequence'>
                                CONSEQUENCES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                When misrepresentation is found, it can result in significant financial loss for the Applicant, inclusion in Immigration, Refugees and Citizenship Canada’s  fraud records, and lead to an inadmissibility ban from entering Canada that can last up to five (5) years.
                            </p>
                            <p>
                                This inadmissibility ban will have consequences on the Applicant’s future applications for entry to Canada, but also affect the Applicant’s credibility in other travel plans to countries that are members of the Five Eyes Intelligence Alliance – Australia, Canada, New Zealand, the United Kingdom and the United States – and have an information-sharing program with Canada.
                            </p>
                            <p>
                                Lastly, a person can be refused entry to Canada, have their legal status in Canada revoked or their application rejected if misrepresentation is found anywhere during the process.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='what_is_the_procedure'>
                                WHAT IS THE PROCEDURE?
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p id='step1'>
                                <span>STEP 1: Procedural Fairness Letter</span>
                            </p>
                            <p>
                                When misrepresentation is found by Immigration, Refugees and Citizenship Canada, they will issue a Procedural Fairness Letter (PFL) to you in which they explain their findings and give you the opportunity to respond. The delay to response may vary and is at the officer’s discretion, depending on the facts that are subject to misrepresentation. An extension of time can be requested from the officer, provided that it is adequately justified.
                            </p>
                            <p id='step2'>
                                <span>STEP 2: Procedural Fairness Letter Respone</span>
                            </p>
                            <p>
                                You will need to draft a response to the Procedural Fairness Letter explaining to the officer explaining why you think you did not commit misrepresentation, or, if you did, that it was an honest mistake. This is a crucial step, and we strongly recommend to obtain assistance from an immigration lawyer – see <b>WHY HIRE A LAWYER?</b>
                            </p>
                            <p id='step3'>
                                <span>STEP 3: Decision</span>
                            </p>
                            <p>
                                After submission of your response to the Procedural Fairness Letter, the officer will issue a decision based on your explanation and the supporting documentation provided. The worse case scenario is a refusal of your application with an inadmissibility ban that can go up to five (5) years.
                            </p>
                            <p id='step4'>
                                <span>STEP 4: Judicial Review</span>
                            </p>
                            <p>
                                You can hire an immigration lawyer to challenge the officer’s refusal and admissibility ban in judicial review. In the proceedings, your legal counsel can only use the Procedural Fairness Letter response, the supporting documentation and your immigration application to defend you. They can’t refer to other documents containing facts that were not brought to the officer’s attention during the decision making process.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='what_hire_a_lawyer'>
                                WHY HIRE A LAWYER?
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The Federal Court’s case law regarding misrepresentation is very strict. Even if an applicant was assisted by a third party in their application, and did not know of the misrepresentation, they retain the ultimate responsibility and duty of due diligence to ensure that they meet their legal obligation for their application to be truthful as per section 16 of the Act :
                            </p>
                            <p>
                                <em>16 (1) A person who makes an application must answer truthfully all questions put to them for the purpose of the examination and must produce a visa and all relevant evidence and documents that the officer reasonably requires.</em>
                            </p>
                            <p>
                                However, exceptions to the general principle arises when there are truly exceptional circumstances where the applicant honestly and reasonably believed they were not misrepresenting as stipulated by section 40 of IRPA – that is, the “innocent mistake” exception.
                            </p>
                            <p>
                                As per the Federal Court’s case law, the “innocent mistake” exception is applicable when the applicant (i) held honest subjective belief that they were not misrepresenting or withholding, (ii) that their belief was reasonable from an objective point of view and (iii) that they had a lack of control over the knowledge of misrepresentation.
                            </p>
                            <p>
                                Consequently, hiring immigration lawyers is crucial, as they will take into account the strict jurisprudence regarding misrepresentation when drafting your Procedural Fairness Letter response and compiling documentation. It is to be repeated that for your defense, your legal counsel is only allowed to use the Procedural Fairness Letter response, the supporting documentation and your immigration application.
                            </p>
                            <p>
                                Far too often, our legal team has had to do damage control for applicants who drafted their own response or hired an immigration consultant to do so. Unfortunately, this has led our lawyers to refuse to take on cases, as the prospects of overturning the decisions were very low.
                            </p>
                        </div>

                        <div className="heading">
                            <h1 id='service'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Far too often, our legal team has had to do damage control for applicants who drafted their own response or hired an immigration consultant to do so. Unfortunately, this has led our lawyers to refuse to take on cases, as the prospects of overturning the decisions were very low.
                            </p>
                            <p>
                                They will determine the prospects of crafting a strong defense and share with you the chances of a success based on their experience and knowledge of the law and Federal Court’s case law. Note that the fees may vary depending on the complexity of the allegations and the factual context of your application.
                            </p>
                            <p>
                                <em>
                                    Book a same-day consultation with our lawyers!
                                </em>
                            </p>
                            <p>
                                <em>Please mention the misrepresentation allegations in your booking. Such cases are treated as a priority, as we strive to accommodate same-day consultations due to the urgency of short delays and serious consequences associated with misrepresentation allegations.

                                </em>
                            </p>

                            {/* <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/misrepresent/04.jpg', alt: 'Image 1' },
                                            { src: '../assets/misrepresent/03.jpg', alt: 'Image 2' },
                                            { src: '../assets/misrepresent/02.jpg', alt: 'Image 3' },
                                            { src: '../assets/misrepresent/01.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div> */}


                        </div>
                    </div>





                </div>
            </div>
        </>
    )
}
