import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';

export default function WorkInCanada() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };
    return (
        <>
            <div className="page-hero work">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>WORK IN CANADA: LMIA WORK PERMIT</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                If you want to work in Canada – whether it be with a Canadian employer or to establish yourself as an entrepreneur – you will need a work permit. Navigating Canada's work permit options can be confusing, and M&C Premier Immigration is committed to helping you find the program that suits you best, considering your professional background and business ventures.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#work-permit" onClick={(e) => { e.preventDefault(); scrollToSection('work-permit'); }}>
                                    WORK PERMIT
                                </a>
                            </li>
                            <li >
                                <a href="#closed-work-permit" onClick={(e) => { e.preventDefault(); scrollToSection('closed-work-permit'); }}>
                                    CLOSED WORK PERMIT
                                </a>
                            </li>
                            <li>
                                <a href="#open-work-permit" onClick={(e) => { e.preventDefault(); scrollToSection('open-work-permit'); }}>
                                    OPEN WORK PERMIT
                                </a>
                            </li>
                            <li>
                                <a href="#lmia" onClick={(e) => { e.preventDefault(); scrollToSection('lmia'); }}>
                                    LMIA WORK PERMIT: <em>find a job in Canada!</em>
                                </a>
                            </li>
                            <li>
                                <a href="#eligibility" onClick={(e) => { e.preventDefault(); scrollToSection('eligibility'); }}>
                                    ELIGIBILITY
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="work-permit">
                            <h1>
                                WORK PERMIT
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                In the context of Canadian immigration, “work” is broadly defined as an activity for which wages are paid or commission is earned.
                            </p>
                            <p id='closed-work-permit'>
                                <span>CLOSED WORK PERMITS </span>
                                <br />
                                Canadian work permits are employer-specific, also referred to as Closed Work Permits. They are granted to foreign workers for a specific job in Canada. You can only work for the employer specified on the document. If you find another job and you do not yet have permanent resident status, you must apply for and receive a new work permit prior to changing employers in Canada.
                            </p>
                            <p id='open-work-permit'>
                                <span>OPEN WORK PERMITS </span>
                                In limited situations, Canadian immigration regulations allow for Open Work Permits, which are not specific to an employer.
                            </p>
                            <p>
                                A work permit will always be temporary in nature but can be extended from within Canada.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='lmia'>
                                LMIA WORK PERMIT :
                                <em> find a job in Canada!</em>
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                There are two categories of work permits in Canada. The first category requires a Labour Market Impact Assessment (<b>LMIA</b>) or “labour market test”. The second category does not require to obtain such document <b>(LMIA-exempt)</b>.
                            </p>
                            <p>
                                If you find a job in Canada, your Canadian employer is only responsible for sharing the LMIA and a detailed job offer letter, which you will need to submit with your work permit application.
                            </p>
                            <p>
                                An <b>LMIA Work Permit</b> requires applicants to provide a Labour Market Impact Assessment (LMIA) with their work permit application. The LMIA demonstrates that the issuance of a work permit to a foreign national will not have a negative impact on the employment and salaries of Canadian workers. This test is conducted by Employment and Social Development Canada (ESDC).
                            </p>
                            <p>
                                A <b>positive or neutral</b> LMIA is issued by ESDC when it is satisfied that there is no Canadian citizen or permanent resident available to do the job.
                            </p>
                            <p>
                                A <b>negative</b> LMIA will be issued when it believes that hiring a foreign national will harm domestic workers in Canada.v
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='eligibility'>
                                ELIGIBILITY
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Who can obtain an open work permit?
                            </p>
                            <p>
                                <ul>
                                    <li>International students with a valid study permit who have graduated from a Canadian post-secondary institution.</li>
                                    <li>Spouses or common-law partners of Canadian citizens or permanent residents.</li>
                                    <li>Dependent children of Canadian citizens or permanent residents.</li>
                                    <li>Other limited situations determined by <em>Immigration, Refugees and Citizenship Canada</em> </li>
                                </ul>
                            </p>
                            <p>
                                <em>Book a consultation with us and determine your eligibility!</em>
                            </p>
                            <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/workincanada/01.jpg', alt: 'Image 1' },
                                            { src: '../assets/workincanada/02.jpg', alt: 'Image 2' },
                                            { src: '../assets/workincanada/04.jpg', alt: 'Image 3' },
                                            { src: '../assets/workincanada/03.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>





                </div>
            </div>




        </>
    )
}
