import React, { useState, useEffect } from 'react';
import '../Css/Hero.css';

export default function Hero() {
    return (
        <>
            <div className="hero">

                <div className="details">
                    <div className="main">
                        <div className="content">
                            <h1>Montreal | Dubai</h1>
                            <hr />
                            <div className="office">
                                <div className="heading">
                                    <h2>LOCATION</h2>
                                </div>
                                <div className="address">
                                    <div className="address-sub">
                                        <h3>
                                            Dubai International Financial Centre,
                                            <br />
                                            Dubai, United Arab Emirates
                                        </h3>

                                    </div>
                                    <div className="address-sub">
                                        <br />
                                        <h3>
                                            Montreal, Quebec, Canada
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="contact">
                                <div className="heading">
                                    <h2>CONTACT</h2>
                                </div>
                                <div className="detail">
                                    <div className="phone">
                                        <div className="title">
                                            <h3>
                                                Phone
                                            </h3>

                                        </div>
                                        <div className="phone-content">
                                            <h3 typeof='button' onClick={() => window.open("tel:+971569886369")} className='button'>
                                                +971 56 988 6369
                                            </h3>
                                            <h3 typeof='button' onClick={() => window.open("tel:+15146076798")} className='button'>
                                                +1 (514) 607 6798
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="phone">
                                        <div className="title">
                                            <h3>
                                                Email
                                            </h3>

                                        </div>
                                        <div className="phone-content">
                                            <h3 typeof='button' onClick={() => window.open("mailto:info@mcpremierimmigration.com", "_blank")} className='button'>
                                                info@mcimm.com
                                            </h3>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
