import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function StratUpVisaPermanentResidence() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };
    return (
        <>
            <div className="page-hero startuppr">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>START-UP VISA PERMANENT RESIDENCE</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The start-up visa program is designed for foreign entrepreneurs who seek to establish themselves in Canada with a business idea. The start-ups can span a variety of industries, including but not limited to healthcare, agriculture, consumer products, renewable energy, finance, education, transportation, manufacturing processes, real estate, technology, and IT.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="content">
                <div className="main">

                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#letter_of_support" onClick={(e) => { e.preventDefault(); scrollToSection('letter_of_support'); }}>
                                    LETTER OF SUPPORT
                                </a>
                            </li>
                            <li>
                                <a href="#designed_orga" onClick={(e) => { e.preventDefault(); scrollToSection('designed_orga'); }}>
                                    DESIGNATED ORGANIZATION
                                </a>
                            </li>
                            <li >
                                <a href="#funds" onClick={(e) => { e.preventDefault(); scrollToSection('funds'); }}>
                                    FUNDS
                                </a>
                            </li>
                            <li >
                                <a href="#language" onClick={(e) => { e.preventDefault(); scrollToSection('language'); }}>
                                    LANGUAGE REQUIREMENTS
                                </a>
                            </li>
                            <li>
                                <a href="#optional_work" onClick={(e) => { e.preventDefault(); scrollToSection('optional_work'); }}>
                                    OPTIONAL WORK PERMIT
                                </a>
                            </li>
                            <li>
                                <a href="servises" onClick={(e) => { e.preventDefault(); scrollToSection('servises'); }}>
                                    OUR SERVICES
                                </a>
                            </li>

                        </ul>
                    </div>


                    <div className="details">
                        <div className="heading">
                            <h1 id='letter_of_support'>
                                LETTER OF SUPPORT
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Within this program, applicants are required to obtain support for their business venture from a designated organization, which will then issue them a Letter of Support (LOS). In this letter, the organization needs to mention that the applicant is an essential member of the start-up and that they are required to be in Canada to begin operations of the business.
                            </p>
                            <p>
                                The mention of being an "essential member" is also crucial for securing a work permit, because Immigration, Refugees and Citizenship Canada requires this assurance to confirm your genuine intention to come to Canada and work on your business while your permanent residency application is being reviewed.
                            </p>
                            <p>
                                This letter is key in the application process as it will be used to apply for permanent residence and a temporary work permit, if needed.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='designed_orga'>
                                DESIGNATED ORGANIZATIONS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Immigration, Refugees and Citizenship Canada has designated a list of venture capital funds, angel investor groups, and business incubators to participate in the Start-Up Visa program. Successful applicants are required to secure a minimum investment or support for their Canadian start-up, depending on the type of organization.
                            </p>
                        </div>
                        <div className="table">
                            <div className="table-clumn">
                                <p>
                                    <h4>
                                        VENTURE CAPITAL FUNDS
                                    </h4>
                                    <br />
                                    The foreign entrepreneur can get one these organizations to agree to invest a minimum of $200,000 in their start-up.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <p>
                                    <h4>
                                        ANGEL INVESTOR
                                    </h4>
                                    <br />
                                    The foreign entrepreneur can get one or more investors connected to these groups to agree to invest a minimum of $75,000 in their start-up.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <p>
                                    <h4>
                                        BUSINESS INCUBATORS
                                    </h4>
                                    <br />
                                    The foreign entrepreneur can be accepted into one of these programs.
                                </p>
                            </div>
                        </div>
                        <div className="text-mid">
                            <p>
                                <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/start-visa/designated-organizations.html">
                                    <em>You can find the list of designated organizations here.</em>
                                </a>
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='funds'>
                                FUNDS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                <span>PERSONAL FUNDS </span>The applicant must have sufficient funds to settle in Canada (“personal funds”). At a minimum, the funds should range between $13,757 and $36,8706 CAD depending on the size of the applicant’s family even if the family members are not accompanying the applicant to Canada.
                            </p>
                            <p>
                                <span>INVESTMENT FUNDS </span>Each member of the start-up should also have access to capital funds to start their venture (“investment fund”). This dual requirement aims to ensure that not only can applicants support themselves and their families upon arrival in Canada but also that they have the financial resources to contribute to the growth and development of their start-up endeavors. The amount depends on the initial capital investment infused in the business, the applicant’s portion of shares or contribution, and the type of designated organization that will support their start-up.
                            </p>
                            <p>
                                The applicant needs to guarantee that the funds will be accessible both at the time of applying for permanent residency and when they obtain permanent residency. They must also be able to prove that they can legally access this money upon their arrival to Canada.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='language'>
                                LANGUAGE REQUIREMENT
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The Applicant needs to achieve Canadian Language Benchmark 5 in listening, reading, writing and speaking in English or French, to qualify for the Start-Up Visa program. The results need to be from a language test done by an approved agency, such as Canadian English Language Proficiency Index Program (CELPIP) or International English Language Testing System (IELTS).
                            </p>
                            <p>
                                <b>Once applicants secure a Letter of Support and fulfill financial and linguistic requirements, the application for permanent residence is submitted. </b>
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='optional_work'>
                                OPTIONAL WORK PERMIT
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The processing time for the permanent residency application can vary and be lengthy. Therefore, in the meantime, the applicant may want to consider applying for a work permit to begin operations of their start-up in Canada while their application undergoes processing.
                            </p>
                            <p>
                                After applying for permanent residency, applicants can apply for a work permit to begin operations of their start-up in Canada while their application undergoes processing <em>(<a href="/start-up-visa-program">see LMIA-exempt work permits : start-up visa program</a>)</em>.
                            </p>
                            <p>
                                Note that if a letter of support was given to an investor group, all essential members of the group must submit their permanent residence applications before any of the essential applicants can get a work permit.
                            </p>
                            <p>
                                Under the start-up visa program, an applicant’s accompanying family members (spouse and children under age 22) can also apply for study permits or open work permits. Each family member will have to fill out a separate application, which will be submitted with the applicant’s work permit.
                            </p>
                        </div>

                        <div className="heading">
                            <h1 id='visite_servises'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                <ul className='special'>
                                    <li>
                                        <em><span>   START-UP CONCEPT  </span></em> At M&C Premier Immigration, we collaborate closely with our clients to refine and develop their initial business idea into a viable and compelling start-up concept.
                                    </li>
                                    <li>
                                        <em><span>   DRAFTING A BUSINESS PLAN  </span></em> Our legal professionals have assisted numerous start-ups in the conception of their venture. We specialize in helping our clients develop thorough business plans that detail their business goals, strategies for growth, market analysis, and operational structure.
                                    </li>
                                    <li>
                                        <em><span>   BUILDING A PITCH DECK  </span></em> We help our clients craft a complete and persuasive pitch deck that effectively communicates your start-up's potential, value and mission.
                                    </li>
                                    <li>
                                        <em><span>   FINANCIAL PROJECTIONS  </span></em> With the help of our experts, we develop detailed financial projections that forecast your start-up's revenue, expenses, and cash flow, providing a realistic outlook on its financial performance in Canadian markets.
                                    </li>
                                    <li>
                                        <em><span>   LETTER OF SUPPORT  </span></em> We guide you through the process of applying for a Letter of Support from a designated organization, which is a mandatory requirement for obtaining a start-up visa in Canada.
                                    </li>
                                    <li>
                                        <em><span>   INCORPORATION IN CANADA  </span></em> Our legal professionals guide you through the process of establishing your start-up in Canada, ensuring full adherence to Federal and Provincial regulations, and offering expert assistance at each stage of the incorporation process.
                                    </li>
                                    <li>
                                        <em><span>   TRADEMARK OR PATENT REGISTRATION  </span></em> We handle the registration process for patents or trademarks, safeguarding your start-up's intellectual property rights and enhancing its competitive advantage.
                                    </li>
                                    <li>
                                        <em><span>   GATHERING DOCUMENTATION  </span></em> We help you compile all necessary documents, such as educational credentials, employment history, and financial records, needed to apply for a work permit in Canada.
                                    </li>
                                    <li>
                                        <em><span>   WORK PERMIT APPLICATION  </span></em> Our lawyers will gather and complete the necessary documentation and submit your work permit application, ensuring accuracy and compliance with immigration regulations to facilitate your employment in Canada.
                                    </li>
                                </ul>
                            </p>

                            <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/startvisapr/01.jpg', alt: 'Image 1' },
                                            { src: '../assets/startvisapr/02.jpg', alt: 'Image 2' },
                                            { src: '../assets/startvisapr/03.jpg', alt: 'Image 3' },
                                            { src: '../assets/startvisapr/04.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>





                </div>
            </div>
        </>
    )
}
