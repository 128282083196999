import React, { useState, useEffect } from 'react';
import '../Css/BlogCard.css';

import { useNavigate } from 'react-router-dom';

const BlogCart = (props) => {
    const navigate = useNavigate();
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {

        const titleElements = document.querySelectorAll('.blog-cart .main .title p');
        let max = 0;
        titleElements.forEach(title => {
            const height = title.getBoundingClientRect().height;
            max = Math.max(max, height);
        });


        setMaxHeight(max);
    }, []);

    return (
        <div className="blog-cart" onClick={() => navigate(`/blog/${props.id}`)}>
            <div className="main ">
                <h6 className='blogs-heading'>{props.title}</h6>
            </div>
        </div>
    );
};

export default BlogCart;




// import '../Css/BlogCard.css';
// import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUser } from '@fortawesome/free-solid-svg-icons';

// export default function BlogCart(props) {
//     return (
//         <div className="blog-cart">
//             <div className="main">
//                 <div className="img">
//                     <img src={props.img} alt="" />
//                 </div>
//                 <div className="create">
//                     <div className="author">
//                         <FontAwesomeIcon icon={faUser} className='icon' />
//                         <p>{props.auth}</p>
//                     </div>
//                     <div className="date">
//                         <p>{props.date}</p>
//                     </div>
//                 </div>
//                 <div className="title">
//                     <p>{props.title}</p>
//                 </div>

//             </div>

//         </div>
//     )
// }
