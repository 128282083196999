import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';

export default function VisiteCanada() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };
    return (
        <>
            <div className="page-hero">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>VISIT CANADA</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                Explore the vibrant streets of Toronto, discover the French culture of Montreal, or witness the northern lights in Yukon – the choice is yours. Whether you're planning a short-term holiday for tourism, visiting family and friends, exploring business opportunities, or expanding your network, a visitor visa may be necessary to enter Canada.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#key" onClick={(e) => { e.preventDefault(); scrollToSection('key'); }}>
                                    KEY TO A SUCCESSFUL APPLICATION
                                </a>
                            </li>
                            <li >
                                <a href="#type_0f_visite" onClick={(e) => { e.preventDefault(); scrollToSection('type_0f_visite'); }}>
                                    TYPES OF VISITOR VISA
                                </a>
                            </li>
                            <li>
                                <a href="#visite_servises" onClick={(e) => { e.preventDefault(); scrollToSection('visite_servises'); }}>
                                    OUR SERVICES
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="key">
                            <h1>
                                KEY TO A SUCCESSFUL APPLICATION
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Immigration, Refugees, and Citizenship Canada requires assurance that you'll leave Canada at the end of your authorized stay. As a foreign national, you must convince the visa officer that your intentions as a visitor are genuine. M&C Premier Immigration is here to help you build a persuasive application that satisfies this requirement.
                            </p>
                            <p>
                                From drafting essential letters, selecting the documents that best showcase your ties to your country of residence, and demonstrating the purpose of your visit to Canada, our team can assist you every step of the way.
                            </p>
                            <p>
                                With extensive experience before the Federal Court, our lawyers possess an in-depth understanding of the legal grounds for refusals, and they will strategically build your application around these crucial factors to increase its strength and likelihood of success.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='type_0f_visite'>
                                TYPES OF VISITOR VISA
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p id="step1">
                                There are two types of Canadian visitor visas:
                            </p>
                            <p >
                                <ul>
                                    <li>
                                        <span>single-entry visa</span>
                                    </li>
                                    <li>
                                        <span>multiple-entry visa</span>
                                    </li>
                                </ul>
                            </p>
                            <p>
                                A <span>single-entry visa </span> grants the foreign national only one entry into Canada, while a multiple-entry visa allows for limitless travel between Canada and other countries until expiry of the document. Applicants are considered for a multiple-entry visa by default unless specific circumstances require a single-entry visa.
                            </p>
                            <p>
                                With a <span>multiple-entry visa</span>, individuals can stay in Canada for up to six (6) months per visit, offering greater flexibility for travel arrangements. The validity period of a visa can extend up to ten (10) years. The exact duration of the validity of your visa is determined by the issuing visa officer
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='visite_servises'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                At M&C Premier Immigration, our lawyers will guide you through the presentation of your purpose of visit, financial documentation and ties to your country of residence. Our priority is to help you emphasize these aspects of your application in a manner that will convince the officer that you will depart Canada at the end of your stay. This includes reviewing your invitation letter(s), and drafting submissions to the visa officer as your legal representatives.
                            </p>
                            <p>
                                Beyond legal representation, our lawyers will assist you in securing the most cost-effective travel accommodations if needed, and lend you a hand in organizing and scheduling your stay in Canada.
                            </p>


                        </div>
                        
                    <div className="img-frame">
                        <div className="frame">
                            <div className="image-container">
                                {[
                                    { src: '../assets/visitcanada/04.jpg', alt: 'Image 1' },
                                    { src: '../assets/visitcanada/03.jpg', alt: 'Image 2' },
                                    { src: '../assets/visitcanada/02.jpg', alt: 'Image 3' },
                                    { src: '../assets/visitcanada/01.jpg', alt: 'Image 4' }
                                ].map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.src}
                                        className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                        onClick={() => handleClick(index)}
                                        alt={image.alt}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    </div>





                </div>
            </div>

        </>
    )
}
