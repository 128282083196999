import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function SelfEmployedPerson() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };

    return (
        <>
            <div className="page-hero self-pr">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">

                                <h1>SELF-EMPLOYED PERSON</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The self-employed permanent residence program is designed for individuals with experience in cultural activities or athletics at a world-class level, or who have been a self-employed person in cultural activities or athletics.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul className='carear'>
                            <li >
                                <a href="#requirments" onClick={(e) => { e.preventDefault(); scrollToSection('requirments'); }}>
                                    REQUIREMENTS
                                </a>
                            </li>
                            <li>
                                <a href="#signifi" onClick={(e) => { e.preventDefault(); scrollToSection('signifi'); }}>
                                    SIGNIFICANT CONTRIBUTIONS
                                </a>
                            </li>
                            <li>
                                <a href="#funds" onClick={(e) => { e.preventDefault(); scrollToSection('funds'); }}>
                                    FUNDS
                                </a>
                            </li>
                            <li>
                                <a href="#criteria" onClick={(e) => { e.preventDefault(); scrollToSection('criteria'); }}>
                                    SIGNIFICANT CONTRIBUTIONS
                                </a>
                            </li>
                            <li>
                                <a href="#servises" onClick={(e) => { e.preventDefault(); scrollToSection('servises'); }}>
                                    OUR SERVICES
                                </a>
                            </li>


                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="requirments">
                            <h1>
                                REQUIREMENTS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                In this program, there is a requirement for applicants to have cumulated relevant experience after a minimum of two (2) years, as set by the government of Canada. This experience must be acquired during the period starting five (5) years before the day you apply, and ending on the day the officer makes a decision on your application.
                            </p>
                            <p>
                                The government of Canada considers the following positions as cultural activities or athletics. However, please note that this list is not exhaustive:
                            </p>
                            <div className="spcial career">
                                <p>
                                    <ul>
                                        <li>Librarians</li>
                                        <li>Archivists</li>
                                        <li>Authors and Writers</li>
                                        <li>Editors</li>
                                        <li>Journalists</li>
                                        <li>Professional Occupations in Public Relations and Communications</li>
                                        <li>Translators</li>
                                        <li>Terminologists and Interpreters</li>
                                        <li>Producers</li>
                                        <li>Directors</li>
                                        <li>Choreographers</li>
                                        <li>Painters</li>
                                        <li>Sculptors and Other Visual Artists</li>
                                        <li>Technical and Skilled Occupations in Art</li>
                                        <li>Culture</li>
                                        <li>Recreation and Sport</li>
                                        <li>Library and Public Archive Technicians</li>
                                        <li>Photographers</li>
                                        <li>Film and Video Camera Operators  </li>
                                        <li>Graphic Arts Technicians </li>
                                        <li>Broadcast Technicians </li>
                                        <li>Audio and Video Recording Technicians </li>
                                        <li>Announcers, Marketers, and Other Broadcasters </li>
                                        <li>Graphic Designers and Illustrators  </li>
                                        <li>Interior Designers and Interior Decorators </li>
                                        <li>Fashion </li>
                                        <li>Exhibit and Other Creative Designers </li>
                                        <li>Artisans and Craftspersons </li>
                                        <li>Patternmakers </li>
                                        <li>Textile  </li>
                                        <li>Leather and Fur Products </li>
                                        <li>Athletes  </li>
                                        <li>Coaches </li>
                                        <li>Sports Officials and Referees  </li>
                                        <li>Program Leaders and Instructors in Recreation, Sport and Fitness </li>

                                    </ul>
                                </p>

                            </div>

                        </div>
                        <div className="heading">
                            <h1 id='signifi'>
                                SIGNIFICANT CONTRIBUTIONS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The applicant must demonstrate that their work will be of significant contribution to specific economic activities. If the applicant meet the test of relevant experience, and there is a reasonable expectation that they will be self-employed, the test of significant contribution becomes relative.
                            </p>
                            <p>
                                For instance, a music instructor aiming to reside in a sparsely populated rural area would be considered significant on a local scale. Conversely, the situation would differ if they were to establish themselves in a densely populated urban area. Likewise, a freelance journalist who contributes to a Canadian publication would satisfy the criteria.
                            </p>
                            <p>
                                Ultimately, the definition of a "significant contribution" is at the discretion of the officer. However, it is not meant to prevent qualified self-employed individuals who are applying in good faith. Its purpose is to deter only frivolous applications.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='funds'>
                                FUNDS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Under the self-employed persons program, candidates must demonstrate their intention and ability to create their own employment in Canada. While there is  <strong>no minimum financial investment required</strong>, an officer may use a candidate’s financial assets to measure their intention and ability to establish themselves economically in Canada.
                            </p>
                            <p>
                                The capital required will depend on the nature of the work, and a candidate must have enough funds to create an employment opportunity for themselves and support themselves and their family members. This includes the ability to be self-supporting until they have established their business in Canada and the self-employment has been created.
                            </p>
                            <p>
                                In addition, a demonstrated ability to support themselves and their and family through their talents in their country of residence could be a good indicator of their ability to continue to do so in Canada.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='criteria'>
                                SELECTION CRITERIA
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                If an officer is satisfied that an applicant meets the regulatory definition of a self-employed person, they will assess the applicant against the point system for the Self-Employed Persons Class established by Immigration, Refugees and Citizenship Canada. Based on the information and documents provided in the application, candidates are assessed on their education level, their age, their ability in english and their adaptability – which is evaluated by their spouse or common-law partner’s level of education, whether they previously worked or studied in Canada and whether they have relatives in Canada.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='servises'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">

                            <p>
                                <ul className='special'>
                                    <li>
                                        <em><span>ELIGIBILITY CHECK </span></em> Initially, M&C Premier Immigration conducts a preliminary assessment by asking you a series of questions to determine if you meet the minimum requirements for the Self-Employed Persons program.
                                    </li>
                                    <li>
                                        <em><span> DOCUMENT PREPARATION </span></em> M&C Premier Immigration will assist you in gathering the required documents to substantiate your eligibility.
                                    </li>
                                    <li>
                                        <em><span>TIMELINE OF YOUR EXPERIENCE </span></em> Our legal team will create a clear and concise timeline of your experience to present to the visa officer, maximizing your chances of a successful application.
                                    </li>
                                    <li>
                                        <em><span>PREPARATION FOR POTENTIAL INTERVIEWS </span></em>   Interviews may not always be necessary, but an officer might want to learn more about your experience. If this occurs, our legal team will help prepare you for the interview, provide guidance on what to expect, and offer examples of appropriate responses.
                                    </li>
                                    <li>
                                        <em><span> APPLYING FOR PERMANENT RESIDENCE </span></em>  M&C Premier Immigration willl care of gathering the required forms and compiling your application for submission to Immigration, Refugees, Citizenship Canada.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                <em>Book and consultation with us to find out if you’re eligible!</em>
                            </p>
                            <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/selfemployeepr/01.jpg', alt: 'Image 1' },
                                            { src: '../assets/selfemployeepr/02.jpg', alt: 'Image 2' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>





                </div>
            </div>

        </>
    )
}
