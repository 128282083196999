import React, { useState, useEffect } from 'react';
import '../Css/Header.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [activeMenuItems, setActiveMenuItems] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleSubMenu = (index) => {
    setActiveMenuItems(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));

    setOpenSubMenu(openSubMenu === index ? null : index);
    const newActiveMenuItems = {};
    for (let i = 0; i < Object.keys(activeMenuItems).length; i++) {
      if (i === index) {
        newActiveMenuItems[i] = true;
      } else {
        newActiveMenuItems[i] = false;
      }
    }
    setActiveMenuItems(newActiveMenuItems);
  };

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  useEffect(() => {
    if (click) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [click]);

  return (
    <>
      <div className="header-top">
        <ul>
          <li className={window.location.pathname === '/' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
            HOME
          </li>
          <li className={window.location.pathname === '/team' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/team'}>
            TEAM
          </li>
          <li className={window.location.pathname === '/blogs' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/blogs'}>
            BLOGS
          </li>
          <li className={window.location.pathname === '/' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
            FAQ
          </li>
          <li className={window.location.pathname === '#contact' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '#contact'}>
            CONTACT US
          </li>
        </ul>
      </div>
      <div className={scrolled ? 'header-main scrolled' : 'header-main'}>
        <div className="main">
          <div className="nav">
            <a href="/">
              <div className="logo">
                <img src="../assets/logo/logo-white.png" alt="" />
              </div>
            </a>
            <a href="/">
              <div className="logo-scrolled">
                <img src="../assets/logo/logo-color.png" alt="" />
              </div>
            </a>
            <div className="nav-menu">
              <ul>
                <li className={window.location.pathname === '/visit-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/visit-canada'}>
                  VISIT
                </li>
                <li className={window.location.pathname === '/study-in-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/study-in-canada'}>
                  STUDY
                </li>
                <li className={window.location.pathname === '/work-in-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/work-in-canada'}>
                  WORK
                </li>
                <li onClick={() => toggleSubMenu(5)} className={openSubMenu === 5 ? 'active' : ''}>
                  BUSINESS
                  <div className="icon">
                    <MdKeyboardArrowDown className="drop-down" />
                  </div>
                  {openSubMenu === 5 && (
                    <div className="sub-menu">
                      <div className="sub-menu-main">
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/lmia-exempt-work-permit'}>
                            LMIA-Exempt
                          </p>
                        </div>

                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/start-up-visa-program'}>
                            Start-up visa
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/entreprenur-or-self-employed-individuals'}>
                            Self-employed or entrepreneurs
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/intra-company-transfer'}>
                            Intracompany transfer
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li onClick={() => toggleSubMenu(6)} className={openSubMenu === 6 ? 'active' : ''}>
                  PERMANENT RESIDENCE
                  <div className="icon">
                    <MdKeyboardArrowDown className="drop-down" />
                  </div>
                  {openSubMenu === 6 && (
                    <div className="sub-menu">
                      <div className="sub-menu-main">

                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/start-up-visa-permanent-residence'}>
                            Start-up visa
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/self-employed-person-permanent-residence'}>
                            Self-employed
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/express-entry'}>
                            Express Entry
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/provicial-nominee-program'}>
                            Provincial nominee
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/atlantic-immigration-program'}>
                            Atlantic Immigration Program
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
                <li onClick={() => toggleSubMenu(7)} className={openSubMenu === 7 ? 'active' : ''}>
                  APPEAL A DECISION
                  <div className="icon">
                    <MdKeyboardArrowDown className="drop-down" />
                  </div>
                  {openSubMenu === 7 && (
                    <div className="sub-menu">
                      <div className="sub-menu-main">

                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/reconsidera-request'}>
                            Reconsideration request
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/judicial-review'}>
                            Judicial review
                          </p>
                        </div>
                        <div className="p">
                          <p typeof='button' onClick={() => window.location.href = '/misrepresentation'}>
                            Misrepresentation
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="header-tab">
        <div className={scrolled ? ' header scrolled' : 'header'}>
          <div className="main">
            <div className='logo'>
              <a href="/">
                <img src="../assets/logo/logo-white.png" alt="" />
              </a>
            </div>
            <div className="logo-scrolled">
              <a href="/">
                <img src="../assets/logo/logo-color.png" alt="" />
              </a>
            </div>
            <div className='menu-icon' onClick={handleClick}>
              <FontAwesomeIcon className='icon' icon={click ? faTimes : faBars} />
            </div>
            <div className={click ? 'menu act' : 'menu'}>
              <div className="nav-menu">
                <ul>
                  <li className={window.location.pathname === '/' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
                    HOME
                  </li>
                  <li className={window.location.pathname === '/team' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/team'}>
                    TEAM
                  </li>
                  <li className={window.location.pathname === '/blogs' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/blogs'}>
                    BLOGS
                  </li>
                  <li className={window.location.pathname === '/fq' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/'}>
                    FAQ
                  </li>
                  <li className={window.location.pathname === '#contact' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '#contact'}>
                    CONTACT US
                  </li>
                </ul>
                <hr />
                <ul>
                  <li className={window.location.pathname === '/visit-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/visit-canada'}>
                    VISIT
                  </li>
                  <li className={window.location.pathname === '/study-in-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/study-in-canada'}>
                    STUDY
                  </li>
                  <li className={window.location.pathname === '/work-in-canada' ? 'active' : ''} typeof='button' onClick={() => window.location.href = '/work-in-canada'}>
                    WORK
                  </li>
                  <li onClick={() => toggleSubMenu(5)} className={openSubMenu === 5 ? 'active' : ''}>
                    <div className='drop-down-li'>
                      BUSINESS
                      <div className="icon">
                        <MdKeyboardArrowDown className="drop-down" />
                      </div>
                    </div>
                    {openSubMenu === 5 && (
                      <div className="sub-menu">
                        <div className="sub-menu-main">
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/lmia-exempt-work-permit'}>
                              LMIA-Exempt
                            </p>
                          </div>

                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/start-up-visa-program'}>
                              Start-up visa
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/entreprenur-or-self-employed-individuals'}>
                              Self-employed or entrepreneurs
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/intra-company-transfer'}>
                              Intracompany transfer
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li onClick={() => toggleSubMenu(6)} className={openSubMenu === 6 ? 'active' : ''}>
                    <div className='drop-down-li'>
                      PERMANENT RESIDENCE
                      <div className="icon">
                        <MdKeyboardArrowDown className="drop-down" />
                      </div>
                    </div>
                    {openSubMenu === 6 && (
                      <div className="sub-menu">
                        <div className="sub-menu-main">

                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/start-up-visa-permanent-residence'}>
                              Start-up visa
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = ' /self-employed-person-permanent-residence'}>
                              Self-employed
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/express-entry'}>
                              Express Entry
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/provicial-nominee-program'}>
                              Provincial nominee
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/atlantic-immigration-program'}>
                              Atlantic Immigration Program
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li onClick={() => toggleSubMenu(7)} className={openSubMenu === 7 ? 'active' : ''}>
                    <div className='drop-down-li'>
                      APPEAL A DECISION
                      <div className="icon">
                        <MdKeyboardArrowDown className="drop-down" />
                      </div>
                    </div>
                    {openSubMenu === 7 && (
                      <div className="sub-menu">
                        <div className="sub-menu-main">

                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/reconsidera-request'}>
                              Reconsideration request
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/judicial-review'}>
                              Judicial review
                            </p>
                          </div>
                          <div className="p">
                            <p typeof='button' onClick={() => window.location.href = '/misrepresentation'}>
                              Misrepresentation
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
