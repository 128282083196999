import React, { useEffect, useState } from 'react'
import '../Css/Pages.css';
export default function ProvicialNomineeProgram() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };


    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };
    return (


        <>
            <div className="page-hero pnp">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>PROVINCIAL NOMINEE PROGRAM</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The Provincial Nominee Program (PNP) is designed for individuals who possess the necessary skills, education, and work experience to enhance the economy while catering to the specific needs of a Canadian province or territory.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    PROVINCIAL NOMINEE PROGRAM
                                </a>
                            </li>
                            <li >
                                <span>
                                    <a href="#services" onClick={(e) => { e.preventDefault(); scrollToSection('services'); }}>
                                        OUR SERVICES
                                    </a>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <a href="#aaip" onClick={(e) => { e.preventDefault(); scrollToSection('aaip'); }}>
                                        ALBERTA ADVANTAGE IMMIGRATION PROGRAM (AAIP)
                                    </a>
                                </span>
                            </li>

                            <li>
                                <a href="#psfw" onClick={(e) => { e.preventDefault(); scrollToSection('psfw'); }}>
                                    Program stream for workers
                                </a>
                            </li>
                            <li >
                                <a href="#psfe" onClick={(e) => { e.preventDefault(); scrollToSection('psfe'); }}>
                                    Program stream for entrepreneurs
                                </a>
                            </li>
                            <li >
                                <span>
                                    <a href="#bcnp" onClick={(e) => { e.preventDefault(); scrollToSection('bcnp'); }}>
                                        BRITISH COLUMBIA NOMINEE PROGRAM (BCNP)
                                    </a>
                                </span>
                            </li>
                            <li >
                                <a href="#sis" onClick={(e) => { e.preventDefault(); scrollToSection('sis'); }}>
                                    Skills immigration stream
                                </a>
                            </li>
                            <li >
                                <a href="#ei" onClick={(e) => { e.preventDefault(); scrollToSection('ei'); }}>
                                    Entrepreneur immigration
                                </a>
                            </li>
                            <li >
                                <span>
                                    <a href="#oinp" onClick={(e) => { e.preventDefault(); scrollToSection('oinp'); }}>
                                        ONTARIO IMMIGRATION NOMINEE PROGRAM (OINP)
                                    </a>
                                </span>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    Foreign Worker Stream
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    International Student Stream
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    In-Demand Skills stream
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    Master’s Graduate Stream
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    PhD Graduate Stream
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    Ontario’s Express Entry Streams
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    Human Capital Priorities Stream
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    Skilled Trades Stream
                                </a>
                            </li>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    French Skilled Worker Stream
                                </a>
                            </li>



                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="two">
                            <h1>
                                PROVINCIAL NOMINEE PROGRAM
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Each province has established its own distinct "streams" or immigration programs tailored to specific groups. For instance, these programs may target students, business professionals, skilled workers, or semi-skilled workers.
                            </p>
                            <p>
                                The candidate must be open to residing in the particular province or territory linked to the Provincial Nominee Program, which will allow them to secure permanent residency while remaining in that specific province or territory.
                            </p>
                        </div>


                        <div className="table">
                            <div className="table-clumn">
                                <h4>
                                    ALBERTA ADVANTAGE IMMIGRATION PROGRAM (AAIP)
                                </h4>
                                <br />
                                <p>
                                    The Alberta Advantage Immigration Program (AAIP) is an economic immigration initiative that selects individuals for permanent residency in Alberta. Candidates must possess skills to address job shortages or intend to establish or purchase a business in Alberta and demonstrate the ability to support their families.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <h4>
                                    BRITISH COLUMBIA NOMINEE PROGRAM (BC PNP)
                                </h4>
                                <br />
                                <p>
                                    The BC Provincial Nominee Program (BC PNP) is an economic immigration initiative that enables the Province to choose economic immigrants to reside in British Columbia and contribute to filling job vacancies or establishing businesses.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <p>
                                    <h4>
                                        ONTARIO IMMIGRANT NOMINEE PROGRAM (OINP)
                                    </h4>
                                    <br />
                                    The OINP serves as the province's economic immigration initiative, operating in collaboration with the Government of Canada. Individuals including foreign workers, international students, and others possessing suitable skills, experience, and education, can seek nomination through the OINP.
                                </p>
                            </div>
                        </div>

                        <div className="heading">
                            <h1 id='services'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The PNP Program covers eleven (11) provinces and territories: Alberta, British Columbia, Manitoba, New Brunswick, Newfoundland and Labrador, Northwest Territories, Nova Scotia, Ontario, Prince Edward Island, Saskatchewan, and Yukon. The territory of Nunavut and the province of Quebec do not participate in provincial nominee programs.
                            </p>
                            <p>
                                The number of available "stream" programs varies by province and territory. At M&C Premier Immigration, our legal team strategically reviews each stream to determine your eligibility. With a thorough understanding of the details and requirements of each program, our immigration lawyers can increase your chances of obtaining permanent residency by selecting the most suitable one for you.
                            </p>
                            <p>
                                When it comes to the step-by-step process, our approach differs depending on whether you meet the criteria for one of the three Express Entry programs. For the non-Express Entry route :
                            </p>
                            <p>
                                <ul className='special'>
                                    <li>
                                        <em><span className='cap'>STEP 1 : Province or Territory Selection </span></em>Our immigration lawyers help you select the PNP that best matches your preferences for living in Canada and suits your personal profile, whether you’re a student, worker or an entrepreneur. They guide you through the application process for nomination under the selected province or territory's non-Express Entry streams.
                                    </li>
                                    <li>
                                        <em><span className='cap'>STEP 2 : Eligibility Check </span></em>Our legal team assesses your eligibility for the PNP in your chosen province or territory. If you're eligible for your chosen province or territory's "stream" program, we move on to STEP 3. Otherwise, we advise you on selecting another PNP that may also suit your preferences.
                                    </li>
                                    <li>
                                        <em><span className='cap'>STEP 3 : Nomination </span></em>After identifying the most suitable "stream" program for you, M&C Premier Immigration contacts the province or territory and submits the application for nomination on your behalf. The province or territory then reviews your application, considering its immigration needs, your compliance with the non-Express Entry stream requirements, and your genuine intention to reside there.
                                    </li>
                                    <li>
                                        <em><span className='cap'>STEP 4 : Apply for Permanent Residence </span></em>If you receive a nomination from the province or territory of your choice, M&C Premier Immigration proceeds to manage the application process for your permanent residence. We handle all the paperwork and ensure that all necessary documents are uploaded accurately to prevent any risks of rejection. Typically, applications with all required documents undergo processing within twelve (12) months.
                                    </li>

                                </ul>
                            </p>
                            <p>
                                <em>You’ll find below a general overview of the some of the Provincial Nominee Programs.</em>
                            </p>
                        </div>

                        <div className="heading">
                            <h1 id='aaip'>
                                ALBERTA ADVANTAGE IMMIGRATION PROGRAM (AAIP)
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The program is a collaborative effort between the governments of Alberta and Canada. Successful nominees, along with their spouse or common-law partner, and dependent children, are eligible to apply for permanent residency.
                            </p>
                            <p id='psfw'>
                                <span>PROGRAM STREAM FOR WORKERS</span>
                            </p>
                        </div>



                        <div className="table-cross spcial">
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Tourism and Hospitality Stream
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Eligible candidates residing and working in Alberta with a full-time job offer from an Alberta-based employer in the tourism and hospitality sector can apply for an AAIP nomination.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Alberta Opportunity Stream
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Eligible candidates residing and working in Alberta with a job offer from an Alberta employer are eligible to apply for an AAIP nomination. This stream aims to create opportunities for workers and is not limited to a specific industry or field.
                                    </p>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Alberta Express Entry Stream
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Qualified candidates may receive invitations to apply for nomination if their profiles in the federal Express Entry pool meet the minimum requirements and align with provincial economic priorities. This stream also offers specific pathways for healthcare professionals and technology workers.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Rural Renewal Stream
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Rural communities must obtain designation before endorsing qualified candidates. Temporary foreign workers with job offers from Alberta employers in those designated communities, along with an endorsement letter, are eligible to apply for an AAIP nomination.
                                    </p>
                                </div>
                            </div>
                        </div>



                        <div className="text-mid">
                            <p id='psfe'>
                                <span>PROGRAM STREAM FOR ENTREPRENEUR</span>
                            </p>
                        </div>


                        <div className="table-cross spcial">
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Rural Entrepreneur Stream
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Entrepreneurs interested in starting or purchasing a business in a rural Alberta community can submit an Expression of Interest
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Graduate Entrepreneur Stream
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        International graduates from approved Alberta post-secondary institutions seeking to establish or acquire a business can submit an Expression of Interest.
                                    </p>
                                </div>
                            </div>


                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Farm Stream
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Experienced farmers planning to purchase or initiate a farm in Alberta are eligible to apply for nomination
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Foreign Graduate Entrepreneur Stream
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Foreign graduates holding degrees from non-Canadian post-secondary institutions aiming to launch a start-up enterprise can collaborate with a designated agency to submit an Expression of Interest
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="heading">
                            <h1 id='bcnp'>
                                BRITISH COLUMBIA NOMINEE PROGRAM
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The BC Provincial Nominee Program (BC PNP) is an economic immigration program that allows the province to select economic immigrants to settle in British Columbia and help fill job vacancies or establish business ventures. If nominated, you and your family can apply to <em> Immigration, Refugees, and Citizenship Canada</em> for permanent residence in Canada.
                            </p>
                            <p>
                                The BC PNP comprises of <b>Skills Immigration</b>, designed for workers and recent graduates possessing the skills, experience, and qualifications sought by employers in British Columbia, and <b>Entrepreneur Immigration</b>, tailored for experienced entrepreneurs aiming to establish and actively manage a business within the province.
                            </p>
                            <p id='sis'>
                                <span>SKILLS IMMIGRATION STREAM</span>
                            </p>
                            <p>
                                Skills Immigration consists of five (5) streams, each with its own specific criteria. Eligibility for many of these streams is based on the National Occupational Classification (NOC) system –  the version of 2021.
                            </p>
                        </div>

                        <div className="table-cross spcial">
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Skilled Worker
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        This stream targets experienced workers in various occupations, aiming to secure permanent residence in British Columbia, and offers an <b>Express Entry option</b>.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Health Authority
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        This stream provides a pathway to permanent residence for individuals working for a health authority in British Columbia, and also <b>offers an Express Entry option</b>.
                                    </p>
                                </div>
                            </div>


                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Entry Level and Semi-Skilled
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        This stream is designed for workers in select occupations in the tourism, hospitality or food processing sectors to obtain permanent residence in British Columbia, but does not <b>include an Express Entry option</b>.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            International Graduate
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        This stream targets recent graduates with a degree or diploma from an eligible Canadian post-secondary institution, and <b>offers an Express Entry option</b>.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            International Post-Graduate
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        This stream targets recent graduates of an eligible British Columbia post-secondary institution with an eligible master’s or doctoral degree, and also  <b>also includes an Express Entry option</b>.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="text-mid">
                            <p id='ei'>
                                <span>ENTREPRENEUR IMMIGRATION</span>
                            </p>
                            <p>
                                The British Columbia Provincial Nominee Program (BC PNP) Entrepreneur Immigration (EI) offers a route for international entrepreneurs aiming to settle in British Columbia, and establish businesses contributing to innovation and economic development in the province. Entrepreneur Immigration operates as a "temporary to permanent" immigration pathway, allowing successful applicants to initially arrive in British Columbia as temporary residents before transitioning to permanent status once their business ventures are underway.
                            </p>
                            <p>
                                The <b>Base Stream</b>  is designed for experienced entrepreneurs aiming to launch new ventures or acquire and expand existing businesses in B.C. Under this stream, candidates must:
                            </p>
                        </div>



                        <div className="table-cross spcial">
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Net worth
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Have a personal net worth exceeding $600,000 CAD.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Experience
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Have possession of business/management experience or a 2-year post-secondary diploma equivalent.
                                    </p>
                                </div>
                            </div>


                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Language
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Have proficiency in basic English or French, meeting or exceeding Canadian Language Benchmark (CLB) level 4.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Immigration status
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Have valid status in their current country of residence and have possession of, or eligibility for, legal immigration status in Canada.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Business
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        establish an eligible new business or enhancing an existing one and commit a qualifying personal investment of at least $200,000 CAD into the business.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Benefits
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        create at least one new full-time job opportunity for either a Canadian citizen or permanent resident.
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="text-mid">

                            <p>
                                The <b>Strategic Projects</b> targets foreign corporations with strategic investment opportunities in British Columbia who aim to establish operations in the province. This stream facilitates the permanent transfer of five (5) members of senior staff possessing corporate knowledge and expertise to British Columbia to actively manage operations. For foreign corporations to qualify, applicants must:
                            </p>
                        </div>

                        <div className="table-cross spcial">
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Net worth
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        be well-established and financially stable demonstrate readiness for investment and capacity for international expansion in the province.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Experience
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        currently be a key staff within the foreign corporation, such as holding a senior position, and possessing qualifications and experience essential for establishing and operating the British Columbia business.
                                    </p>
                                </div>
                            </div>


                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Business
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        establish a new business or purchase and expand an eligible business in British Columbia and invest a minimum of $500,000 in equity directed towards its operations.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Role
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        be appointed to executive or senior managerial roles, or possess specialized knowledge crucial for the proposed British Columbia operation and commit to permanent full-time employment at the British Columbia operation, receiving a salary commensurate with their expertise.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Benefits
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        create at least three new full-time equivalent jobs for Canadian citizens or permanent residents per foreign key staff member proposed (up to a maximum of five key staff).
                                    </p>
                                </div>
                            </div>
                        </div>





                        <div className="heading">
                            <h1 id='oinp'>
                                ONTARIO IMMIGRATION NOMINEE PROGRAM
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The OINP identifies and nominates candidates for permanent residency based on their skills and experience deemed valuable to Ontario's economy. As with other Provincial Nominee Programs, the final decision to approve applications for permanent residence is incumbent upon the Government of Canada.
                            </p>
                            <p>
                                Ontario's Expression of Interest Streams operate through the OINP's Expression of Interest System. To be eligible, you must register an expression of interest and receive an invitation to apply (ITA).
                            </p>
                        </div>

                        <div className="table-sp">
                            <div className="table-row head">
                                <div className="table-clumn">
                                    <p>
                                        <strong>
                                            A Job offer in Ontario
                                        </strong>
                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        <strong>
                                            A Masters or PhD degree from an Ontario university
                                        </strong>

                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        <strong>
                                            Skills and experience sought by Ontario employers
                                        </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="table-row ">
                                <div className="table-clumn">
                                    <p>
                                        The <b>Foreign Worker</b> stream is suitable for skilled foreign workers with a job offer in Ontario.
                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        The <b>Masters Graduate</b> stream  targets at those with a master's degree from an Ontario university.
                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        The <b>Human Capital Priorities stream</b> is suitable for candidates with required skilled work experience, education, and language abilities.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row ">
                                <div className="table-clumn">
                                    <p>
                                        The <b>International Student</b> stream is designed for recent graduates with a job offer in Ontario.
                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        The <b>PhD Graduate stream</b> is designed for individuals with a PhD degree from an Ontario university.
                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        The <b>Skilled Trades stream</b> is geared towards foreign workers with Ontario work experience in eligible skilled trades.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row ">
                                <div className="table-clumn">
                                    <p>
                                        The <b>In-Demand Skills stream</b> is intended for foreign workers in high-demand occupations with a job offer in Ontario.
                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        The <b>Ontario's Express Entry Streams</b> operates through the federal government's Express Entry System, require an Express Entry profile and a notification of interest from Ontario.
                                    </p>
                                </div>
                                <div className="table-clumn">
                                    <p>
                                        The <b>French-Speaking Skilled Worker stream</b> is intended for French-speaking foreign workers with strong English language proficiency.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="text-mid">
                            <p>
                                <em>Book a strategic consultation and let us assess your eligibility!</em>
                            </p>
                        </div>

                        {/* 
                        <div className="text-mid">
                            <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/pnp/04.jpg', alt: 'Image 1' },
                                            { src: '../assets/pnp/03.jpg', alt: 'Image 2' },
                                            { src: '../assets/pnp/02.jpg', alt: 'Image 3' },
                                            { src: '../assets/pnp/01.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </div> */}
                    </div>





                </div>
            </div>





        </>
    )
}
