import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function ExpressEntry() {


    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };



    return (


        <>
            <div className="page-hero express">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>EXPRESS ENTRY</h1>
                            </div>
                        </div>
                        <div className="introduce">

                            <p>
                                There are three immigration programs managed through Express Entry. These programs vary in their minimum requirements for eligibility, despite their general similarity.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#express_entry" onClick={(e) => { e.preventDefault(); scrollToSection('express_entry'); }}>
                                    EXPRESS ENTRY PROGRAM
                                </a>
                            </li>
                            <li >
                                <span>
                                    <a href="#canadian_exprience_class" onClick={(e) => { e.preventDefault(); scrollToSection('canadian_exprience_class'); }}>
                                        CANADIAN EXPERIENCE CLASS
                                    </a>
                                </span>
                            </li>
                            <li>
                                <a href="#cec_requri" onClick={(e) => { e.preventDefault(); scrollToSection('cec_requri'); }}>
                                    Requirements
                                </a>
                            </li>
                            <li>
                                <a href="#cec_experience" onClick={(e) => { e.preventDefault(); scrollToSection('cec_experience'); }}>
                                    Experience
                                </a>
                            </li>
                            <li>
                                <a href="#cec_langua" onClick={(e) => { e.preventDefault(); scrollToSection('cec_langua'); }}>
                                    Language abilities
                                </a>
                            </li>
                            <li >
                                <a href="#cec_eduacation" onClick={(e) => { e.preventDefault(); scrollToSection('cec_eduacation'); }}>
                                    Education
                                </a>
                            </li>

                            <li>
                                <span>
                                    <a href="#federal_skilled_class" onClick={(e) => { e.preventDefault(); scrollToSection('federal_skilled_class'); }}>
                                        FEDERAL SKILLED WORKERS
                                    </a>
                                </span>
                            </li>
                            <li>
                                <a href="#fsc_requri" onClick={(e) => { e.preventDefault(); scrollToSection('fsc_requri'); }}>
                                    Requirements
                                </a>
                            </li>
                            <li>
                                <a href="#fsc_experience" onClick={(e) => { e.preventDefault(); scrollToSection('fsc_experience'); }}>
                                    Experience
                                </a>
                            </li>
                            <li >
                                <a href="#fsc_student_work_ex" onClick={(e) => { e.preventDefault(); scrollToSection('fsc_student_work_ex'); }}>
                                    Student work experience
                                </a>
                            </li>
                            <li >
                                <a href="#fsc_langua" onClick={(e) => { e.preventDefault(); scrollToSection('fsc_langua'); }}>
                                    Language abilities
                                </a>
                            </li>
                            <li>
                                <a href="#fsc_eduacation" onClick={(e) => { e.preventDefault(); scrollToSection('fsc_eduacation'); }}>
                                    Education
                                </a>
                            </li>
                            <li>
                                <a href="#fsc_selection_factors" onClick={(e) => { e.preventDefault(); scrollToSection('fsc_selection_factors'); }}>
                                    Selection Factors
                                </a>
                            </li>
                            <li>
                                <a href="#fsc_funds2" onClick={(e) => { e.preventDefault(); scrollToSection('fsc_funds2'); }}>
                                    Funds
                                </a>
                            </li>
                            <li>
                                <span>
                                    <a href="#federal_skilled_trade" onClick={(e) => { e.preventDefault(); scrollToSection('federal_skilled_trade'); }}>
                                        FEDERAL SKILLED TRADES
                                    </a>
                                </span>
                            </li>
                            <li>
                                <a href="#fst_requri" onClick={(e) => { e.preventDefault(); scrollToSection('fst_requri'); }}>
                                    Requirements
                                </a>
                            </li>
                            <li>
                                <a href="#fst_experience" onClick={(e) => { e.preventDefault(); scrollToSection('fst_experience'); }}>
                                    Experience
                                </a>
                            </li>
                            <li>
                                <a href="#fst_job_offer" onClick={(e) => { e.preventDefault(); scrollToSection('fst_job_offer'); }}>
                                    Job offer or certification of qualification
                                </a>
                            </li>
                            <li>
                                <a href="#fst_langua" onClick={(e) => { e.preventDefault(); scrollToSection('fst_langua'); }}>
                                    Language Ability
                                </a>
                            </li>
                            <li>
                                <a href="#fst_eduacation" onClick={(e) => { e.preventDefault(); scrollToSection('fst_eduacation'); }}>
                                    Education
                                </a>
                            </li>
                            <li>
                                <a href="#fst_funds" onClick={(e) => { e.preventDefault(); scrollToSection('fst_funds'); }}>
                                    Funds
                                </a>
                            </li>
                            <li>
                                <span>
                                    <a href="#study-service" onClick={(e) => { e.preventDefault(); scrollToSection('study-service'); }}>
                                        OUR SERVICES
                                    </a>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="express_entry">
                            <h1>
                                EXPRESS ENTRY
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Express Entry is a system used by Immigration, Refugees, and Citizenship Canada to select candidates for the government of Canada through the Federal Skilled Worker (FSW), the Federal Skilled Trades (FST), the Canadian Experience Class (CEC) and few of the Provincial Nominee Programs.
                            </p>
                            <p>
                                The Express Entry selection can be general or program specific. In the context of general selections, candidates eligible for any federal economic immigration program may be selected by Immigration, Refugees, and Citizenship Canada based solely on their CRS score. In program-specific selections, candidates are selected for just one of the federal economic immigration programs.
                            </p>
                            <p>
                                There are three immigration programs managed through Express Entry. These programs vary in their minimum requirements for eligibility, despite their general similarity.
                            </p>
                            <p>
                                The three distinct programs and their minimal prerequisites are listed below:
                            </p>
                        </div>
                        <div className="table">
                            <div className="table-clumn">
                                <h4>
                                    Canadian Experience Class
                                </h4>
                                <p>

                                    The Canadian Experience Class is for skilled workers who have Canadian work experience seeking permanent residency <b>(Canadian skilled work experience, Language proficiency)</b>
                                </p>
                            </div>
                            <div className="table-clumn">
                                <h4>
                                    Federal Skilled Worker Program
                                </h4>
                                <p>
                                    The Federal Skilled Worker Program is for skilled workers who have work experience seeking permanent residency <b>(Skilled work experience, Language proficiency, Education)</b>
                                </p>
                            </div>
                            <div className="table-clumn">
                                <h4>
                                    Federal Skilled Trade Program
                                </h4>
                                <p>

                                    The Federal Skilled Trades Program is for skilled workers seeking permanent residency based on being qualified in a skilled trade <b>(Skilled work experience, Job offer or certificate of qualification, Language proficiency)</b>
                                </p>
                            </div>
                        </div>


                        <div className="heading">
                            <h1 id='canadian_exprience_class'>
                                CANADIAN EXPERIENCE CLASS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p id='cec_requri'>
                                <span>REQUIREMENTS </span>To be eligible for the Canadian Experience Class, candidates must satisfy all the minimum requirements for Canadian skilled work experience and language ability.
                            </p>
                            <p id='cec_experience'>
                                <span>EXPERIENCE </span>A foreign national must have at least one (1) year of skilled work experience in Canada within the three (3) years preceding their application or an equivalent amount of part-time work experience. This experience must have been gained while they were authorized to work under temporary resident status in Canada.
                            </p>
                            <p>
                                The applicant’s responsibilities and tasks should align with those listed in the lead statement of the occupational description in the National Occupational Classification (NOC), covering all essential duties and most main duties.  The NOC is a systematic classification of all occupations in the Canadian labour market. Each occupation in the NOC are assigned a certain specific level of Training, Education, Experience and Responsibilities (TEER) by the government of Canada.
                            </p>
                            <p>
                                To be qualified as skilled work experience, an applicant’s experience must fall under the TEER categories 0, 1, 2, or 3, as reproduced below:
                            </p>
                        </div>



                        <div className="table-cross">
                            <div className="table-row table-head">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            TEER
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        <strong>
                                            Occupation types
                                        </strong>
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            TEER 0
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Management occupations
                                    </p>
                                </div>
                            </div>


                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            TEER 1
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Occupations that usually require a university degree
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            TEER 2
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Occupations that usually require either : a college diploma, an apprenticeship training of two (2) or more years, or supervisory occupations.
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            TEER 3
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Occupations that usually require either : a college diploma, apprenticeship training of less than two (2) years, OR more than 6 months of on-the-job training.
                                    </p>
                                </div>
                            </div>

                        </div>


                        <div className="text-mid">
                            <p>
                                In addition, the work must be paid. This excludes volunteer work, unpaid internships, self-employment and work experience acquired as a full-time student, including co-op work terms.
                            </p>
                            <p id='cec_langua'>
                                <span>LANGUAGE ABILITIES </span>The applicant must take approved language tests for writing, reading, listening, and speaking, and meet the minimum score required. These test results are valid for two (2) years from the date of the test and must be valid on the day the permanent residence application is submitted.
                            </p>
                            <p id='cec_eduacation'>
                                <span>EDUCATION </span>There is no specific education requirement for the Canadian Experience Class. However, you can improve your ranking in the Express Entry pool by obtaining points for educational credentials obtained in Canada or through an educational credential assessment report for immigration purposes from a designated organization, demonstrating equivalence to Canadian secondary or post-secondary education.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='federal_skilled_class'>
                                FEDERAL SKILLED WORKERS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p id='fsc_requri'>
                                <span>REQUIREMENTS </span>Under this program, candidates must satisfy all the minimum requirements in three key areas: skilled work experience, language ability, and education. Once these minimum requirements are met, Immigration, Refugees and Citizenship Canada will evaluate your application based on additional selection factors.
                            </p>
                            <p id='fsc_experience'>
                                <span>EXPERIENCE </span>Skilled work experience involves working in categories 0, 1, 2 and 3 of the TEER classification system. The applicant needs to demonstrate that, while employed in their primary occupation, they performed the main responsibilities and essential duties outlined by the lead statement of the occupational description in the NOC.
                            </p>
                            <p>
                                The applicant’s skilled work experience must be in the same type of job (have the same NOC) as the one they are applying for in the context of their immigration application (referred to as the primary occupation). The experience must also have been acquired within the past ten (10) years, either in Canada or abroad. This includes paid work, with wages or commission earned, but not volunteer work, unpaid internships and self-employment.
                            </p>
                            <p>
                                The applicant can meet this requirement through either full-time employment at one job for thirty (30) hours per week for twelve (12) months (1,560 hours), an equivalent amount of part-time work, such as fifteen (15) hours per week for twenty-four (24) months or working multiple part-time jobs to accumulate the required hours.
                            </p>
                            <p>
                                For part-time work, the applicant can work more or less than fifteen (15) hours per week, as long as the total hours reach one thousand five hundred sixty (1,560). Note that all hours worked beyond thirty (30) per week are not counted.
                            </p>
                            {/* <p>
                                In addition, the work must be paid. This excludes, however, volunteer work, unpaid internships, self-employment and work experience acquired as a full-time student, including co-op work terms.
                            </p>
                            <p id='fsc_funds'>
                                <span>FUNDS</span>You must demonstrate sufficient funds to settle in Canada unless you are currently authorized to work in Canada and have a valid job offer from a Canadian employer.
                            </p> */}
                            <p id='fsc_student_work_ex'>
                                <span>STUDENT WORK EXPERIENCE  </span>Work experience gained during your studies may count towards the minimum requirements if it meets specific criteria, namely payment by wages or commissions, continuous without employment gaps, and if it meets all other program requirements.
                            </p>
                            <p id='fsc_langua'>
                                <span>LANGUAGE ABILITIES </span>The applicant must take approved language tests in English or French for writing, reading, listening, and speaking, meeting the minimum score required in all four abilities. The test results are valid for two (2) years and must be valid on the day you apply for permanent residence (CELPIP, IELTS, PTE).
                            </p>
                            <p id='fsc_eduacation'>
                                <span>EDUCATION </span>If the applicant attended school in Canada, they must possess a certificate, diploma, or degree from a Canadian secondary or post-secondary institution. For foreign education, the applicant must hold a completed educational credential and an Educational Credential Assessment for immigration purposes from a designated organization, demonstrating equivalence to a Canadian certificate, diploma, or degree.
                            </p>
                            <p id='fsc_selection_factors'>
                                <span>SELECTION FACTORS </span>If you meet all minimum requirements, Immigration, Refugees and Citizenship Canada will assess your eligibility for the Federal Skilled Worker Program based on six (6) factors: age, education, work experience, valid job offer, language skills, and adaptability. An overall score out of hundred (100) will be assigned, with a current pass mark of sixty-seven (67) points. These points are different from the ones used by Immigration, Refugees and Citizenship Canada to assess and rank your profile in the Express Entry pool.
                            </p>
                            <p id='fsc_funds2'>
                                <span>FUNDS </span>You must demonstrate sufficient funds for you and your family to settle in Canada, unless you are currently authorized to work in Canada or have a valid job offer from a Canadian employer. The amount may vary between $13,757 CAD to $36,407 CAD, depending on the number of family members.
                            </p>
                        </div>


                        <div className="heading">
                            <h1 id='federal_skilled_trade'>
                                FEDERAL SKILLED TRADE
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p id='fst_requri'>
                                <span>REQUIREMENTS </span>To qualify for the program, the applicant must meet all the minimum requirements related to their skilled trades work experience, job offer or certificate of qualification, and language ability.
                            </p>
                            <p id='fst_experience'>
                                <span>EXPERIENCE </span>The applicant will need to have a minimum of two (2) years of full-time work experience (or equivalent part-time experience) in a skilled trade within the five (5) years preceding your application, and meet the job requirements set out in the NOC, demonstrating your ability to perform all essential duties and most main duties listed. In addition, your work experience must be paid (wages or commission), and volunteer work or unpaid internships are not considered. You must be qualified to independently practice the trade occupation during your work.
                            </p>
                            <p>
                                Your work experience must also fall within specific NOC groups:
                            </p>
                        </div>


                        <div className="table-cross spcial">
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Major Group 72
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Technical trades and transportation officers and controllers (excluding Sub-Major Group 726)
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Major Group 73
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        General trades
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Major Group 82
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Supervisors in natural resources, agriculture, and related production
                                    </p>
                                </div>
                            </div>


                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Major Group 83
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Occupations in natural resources and related production
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Major Group 92
                                        </strong>
                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Processing, manufacturing, and utilities supervisors, and utilities operators and controllers
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Major Group 93
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Central control and process operators and aircraft assembly assemblers and inspectors (excluding Sub-Major Group 932)
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Minor Group 6320
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Minor Group 6320	Cooks, butchers, and bakers
                                    </p>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="part-start">
                                    <p>
                                        <strong>
                                            Unit Group 62200
                                        </strong>

                                    </p>
                                </div>
                                <div className="part-end">
                                    <p>
                                        Chefs
                                    </p>
                                </div>
                            </div>

                        </div>





                        <div className="text-mid">
                            <p id='fst_job_offer'>
                                <span>JOB OFFER OR CERTIFICATION OF QUALIFICATION </span>You must possess a valid job offer for full-time employment lasting at least one year, or a certificate of qualification in your skilled trade issued by a Canadian provincial, territorial, or federal authority. This certificate confirms your eligibility to work in a specific skilled trade in Canada. To obtain it, you must pass a certification exam and fulfill all requirements to practice your trade in the issuing province or territory.
                            </p>
                            <p id='fst_langua'>
                                <span>LANGUAGE ABILITIES </span>To prove that you have the required language proficiency, you must take approved language tests (CELPIP, IELTS, PTE) in writing, reading, listening, and speaking and meet the minimum score requirements in all four abilities. Once you obtain your results, you need to enter the test results in your Express Entry profile. Note that all language test results are valid for two (2) years from the test date and must be valid on the day you apply for permanent residence.
                            </p>
                            <p id='fst_eduacation'>
                                <span>EDUCATION </span>There is no specific education requirement for the Federal Skilled Trades Program. However, you can earn additional points in the Express Entry pool if you hold educational credentials from Canadian or designated foreign institutions.
                            </p>
                            <p id='fst_funds'>
                                <span>FUNDS </span>You must demonstrate sufficient funds to settle in Canada unless you are currently authorized to work in Canada and have a valid job offer from a Canadian employer.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='study-service'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The process of verifying your eligibility and building an application is detail-oriented, and can be time-consuming. To ensure accuracy in evaluating your profile and to prevent any delays, extra expenses, or application rejection, it's advisable to hire immigration lawyers to handle your application preparation, maximizing your chances of success.
                            </p>
                            <p>
                                <ul className='special'>
                                    <li>
                                        <em> <span>ELIGIBILITY CHECK   </span></em> Initially, M&C Premier Immigration conducts a preliminary assessment by asking you a series of questions to determine if you meet the minimum requirements for each program within Express Entry.
                                    </li>
                                    <li>
                                        <em><span>SCORE EVALUATION </span></em> Upon confirming your eligibility for one or more Express Entry programs, we proceed to evaluate your score using the Comprehensive Ranking System (CRS).
                                    </li>
                                    <li>
                                        <em><span>DOCUMENT PREPARATION </span></em>M&C Premier Immigration will assist you in gathering essential documents required to substantiate your eligibility.
                                    </li>
                                    <li>
                                        <em><span>PROFILE COMPLETION </span></em>M&C Premier Immigration will create your Express Entry profile, ensuring that all necessary information is accurately provided. If deemed eligible, Immigration, Refugees, and Citizenship Canada will accept you into the candidate pool and assign you a CRS score.
                                    </li>
                                    <li>
                                        <em><span>INVITATION AND APPLICATION SUBMISSION </span></em>If Immigration, Refugees, and Citizenship Canada identifies you as one of the top-scoring candidates in the pool, you will receive an invitation to apply. If invited, our team submits your application for permanent residence under Express Entry within the sixty (60) day deadline.
                                    </li>

                                </ul>
                            </p>
                            <p>
                                We handle all the paperwork and ensure that all necessary documents are uploaded accurately to prevent any risks of rejection. Typically, applications with all required documents undergo processing within six (6) months.
                            </p>

                            {/* <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/expressentry/01.jpg', alt: 'Image 1' },
                                            { src: '../assets/expressentry/02.jpg', alt: 'Image 2' },
                                            { src: '../assets/expressentry/03.jpg', alt: 'Image 3' },
                                            { src: '../assets/expressentry/04.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div> */}


                        </div>
                    </div>





                </div>
            </div>







        </>
    )
}
