import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { blogsData } from '../Data/BlogsData';
import '../Css/BlogPage.css';

export default function Blog() {
    const params = useParams();
    const props = blogsData.find((element) => element.id === parseInt(params.id));
    return (
        <>
            <div className="blogs-hero " >
                <div className="main">
                    <div className="sub">
                        <div className="inner">
                            <div className="header">
                                <div className="border">
                                </div>
                                <div className="headings">
                                    <h1>
                                        {props.title}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Deatail */}
            {/* Deatail */}
            {/* Deatail */}
            <div className="create-part">
                <div className="create">
                    <div className="author">
                        <p><span>Author: </span>{props.auth}</p>
                    </div>
                    <div className="date">
                        <p>{props.date}</p>
                    </div>
                </div>

                <div className="details">
                    {props.details}
                </div>
            </div>

        </>
    )
}
