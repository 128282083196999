import React, { useState, useEffect } from 'react';
import '../Css/Footer.css';
import { BsWhatsapp, BsInstagram, BsLinkedin, BsTwitter, BsFacebook } from "react-icons/bs";

export default function Footer() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabs = [
    {
      label: 'UAE OFFICE',
      content:
        <p>
          Dubai International Financial Centre,
          <br />
          Dubai, United Arab Emirates
          <br />
        </p>,
      phone:
        <p typeof='button' onClick={() => window.open("tel:+971569886369")} className='button'>
          +971 56 988 6369
          <br />
        </p>,
      email:
        <p typeof='button' onClick={() => window.open("mailto:info@mcpremierimmigration.com", "_blank")} className='button'>
          info@mcimm.com
          <br />
        </p>,
    },
    {
      label: 'CANADA OFFICE',
      content:
        <p>
          Montreal, Quebec, Canada
          <br />
          <br />
        </p>,
      phone:
        <p typeof='button' onClick={() => window.open("tel:+15146076798")} className='button'>
          +1 (514) 607 6798
        </p>,
      email:
        <p typeof='button' onClick={() => window.open("mailto:info@mcpremierimmigration.com", "_blank")} className='button'>
          info@mcimm.com
        </p>,
    },
  ];

  return (
    <>
      <div className="footer">
        <div className="footer-top">
          <ul>
            <li typeof='button' onClick={() => window.location.href = '/'}>
              HOME
            </li>
            <li typeof='button' onClick={() => window.location.href = '#team'}>
              TEAM
            </li>
            <li typeof='button' onClick={() => window.location.href = '/blogs'}>
              BLOGS
            </li>
            <li typeof='button' onClick={() => window.location.href = '/'}>
              FAQ
            </li>
            <li typeof='button' onClick={() => window.location.href = '#contact'}>
              CONTACT US
            </li>
          </ul>
        </div>
        <div className="footer-main">
          <div className="logo">
            <div className="des">
              <h2 className='heading'>
                Moorcroft & Chazbek Premier Immigration
              </h2>
              <hr />
              <p>
                Moorcroft & Chazbek Premier Immigration, founded by two Canadian lawyers, is a law firm that specializes in immigration law. With presence in the United Arab Emirates and Canada, our firm serves a diverse global clientele.
              </p>
            </div>
            <div className="whatsapp">
              <h2>
                Let's Chat on WhatsApp
              </h2>
              <hr />
              <div className="whatsapp-button" typeof='button' onClick={() => window.open("https://api.whatsapp.com/send?phone=971569886369", "_blank")}>
                <div className="icon">
                  <BsWhatsapp className='icon-whatsapp' />
                </div>
                <p>+971 56 988 6369</p>
              </div>
              <div className="whatsapp-button" typeof='button' onClick={() => window.open("https://api.whatsapp.com/send?phone=15146076798", "_blank")}>
                <div className="icon">
                  <BsWhatsapp className='icon-whatsapp' />
                </div>
                <p>+1 (514) 607 6798</p>

              </div>
            </div>
            <div className="icon-list">
              <BsInstagram className='icon-social' />
              <BsLinkedin className='icon-social' />
              <BsFacebook className='icon-social' />
              <BsTwitter className='icon-social' />
            </div>
          </div>
          <div className="address enter">
            {/* Tabs */}
            {/* Tabs */}
            {/* Tabs */}
            <div className='main'>
              <div className="tab-header">
                {tabs.map((tab, index) => (
                  <div
                    key={index}
                    className={`tab-item ${activeTab === index ? 'active' : ''}`}
                    onClick={() => handleTabClick(index)}
                  >
                    <h3>{tab.label}</h3>
                  </div>
                ))}
              </div>
              <div className="tab-content">
                <p><strong>Address</strong></p>
                <hr />
                {tabs[activeTab].content}
                <p><strong>Phone</strong></p>
                <hr />
                {tabs[activeTab].phone}
                <p ><strong>Email</strong></p>
                <hr />
                {tabs[activeTab].email}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>
            Copyright © 2024 <span>All rights reserved Moorcroft & Chazbek Premier Immigration</span>
          </p>
          <p className="privacy" typeof='button' onClick={() => window.location.href = '/privacy-policy'}>
            Privacy Policy
          </p>
        </div>
      </div>

    </>
  );
}
