import React, { useEffect, useRef, useState } from 'react';
import '../Css/Pages.css';
import '../Css/Blogs.css';
import { blogsData } from '../Data/BlogsData';
import BlogCart from './BlogCart';

export default function Blogs() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };


    const [items, setItems] = useState(blogsData);


    const filterItems = (year) => {
        if (year === "2024") {
            return items.filter((item) => item.year === year);
           
        } else {
            return items;
        }
    };
    const filterItems1 = (year) => {
        if (year === "2023") {
            return items.filter((item) => item.year === year);
        } else {
            return items;
        }
    };

    const filteredItems2024 = filterItems("2024");
    const filteredItems2023 = filterItems1("2023");
  

    return (
        <>
            {/* Hero */}
            {/* Hero */}
            {/* Hero */}
            <div className="page-hero blog">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>BLOGS </h1>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="content team">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#2024" className="Year" onClick={(e) => { e.preventDefault(); scrollToSection('2024'); }}>
                                    <h6>
                                        2024
                                    </h6>
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="2024">
                        {filteredItems2024.map((product) => <BlogCart{...product} />)}
                    </div>
                </div>
            </div>


            <div className="content team">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#2023" className="Year" onClick={(e) => { e.preventDefault(); scrollToSection('2023'); }}>
                                    <h6>
                                        2023
                                    </h6>
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="2023">
                        {filteredItems2023.map((product) => <BlogCart{...product} />)}
                    </div>
                </div>
            </div>


        </>
    )
}
