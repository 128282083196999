import React from 'react';
import Mission from "../Components/Mission.js";
import Hero from "../Components/Hero.js";
import Form from "../Components/Form.js";
import WhoWe from "../Components/WhoWe.js";
import Expert from "../Components/Expert.js";
import Service from "../Components/Service.js";
import Clints from "../Components/Clints.js";
import ContactUs from "../Components/ContactUs.js";
import About from "../Components/About.js";

export default function Home() {
  return (
    <>
      <Hero />
      <About/>
      <WhoWe />
      <Mission />
      <Expert />
      <Service />
      {/* <MidImages /> */}
      <Clints />
      <ContactUs />

      {/* <Tabs/> */}

    </>
  )
}
