import React, { useEffect, useState } from 'react';
import '../Css/Pages1.css';

const LmiaExemptWorkPermit = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };

    return (
        <>
            <div className="page-hero lima">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>
                                    BUSINESS: LMIA-EXEMPT WORK PERMIT — pathway for entrepreneurs!
                                </h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                A labour market test will not be required for work permits which are LMIA-exempt. These type of work permits will be issued under various business immigration streams to foreign workers when their work can result in significant economic, social or cultural benefits to Canadians.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#work-permit" onClick={(e) => { e.preventDefault(); scrollToSection('work-permit'); }}>
                                    LMIA EXEMPT WORK PERMITS
                                </a>
                            </li>
                            <li >
                                <a href="#closed-work-permit" onClick={(e) => { e.preventDefault(); scrollToSection('closed-work-permit'); }}>
                                    START-UP VISA
                                </a>
                            </li>
                            <li>
                                <a href="#open-work-permit" onClick={(e) => { e.preventDefault(); scrollToSection('open-work-permit'); }}>
                                    ENTREPRENEUR OR SELF-EMPLOYED INDIVIDUALS
                                </a>
                            </li>
                            <li>
                                <a href="#lmia" onClick={(e) => { e.preventDefault(); scrollToSection('lmia'); }}>
                                    INTRACOMPANY TRANSFER
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">

                        <div className="text-mid">
                            <p>
                                The Canadian immigration landscape provides for various LMIA-exempt work permits designed to facilitate the admission of those seeking to establish or expand their ventures in Canada and contribute to the country’s economic growth.
                            </p>
                            <p>
                                These pathways offer opportunities for talented individuals to bring their business ideas to fruition. Each program offers a unique set of requirements and benefits, catering to different profiles of entrepreneurs and business owners.
                            </p>
                            <p id='open-work-permit'>
                                <span>
                                    <em>
                                        Which of these key programs would be best suited to help make Canada your new home?
                                    </em>
                                </span>
                            </p>
                        </div>
                        <div className="table">
                            <div className="table-clumn">
                                <p>
                                    <span>START-UP VISA </span>
                                    is a temporary work permit under the International Mobility Program issued to foreign professionals who present a significant social, cultural, or economic benefit to Canada by starting a business that creates jobs, brings new technologies to Canada, contributes to Canada’s cultural landscape or causes to significant advancements in their industry.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <p>
                                    <span>ENTREPRENEUR OR SELF-EMPLOYED INDIVIDUALS </span>
                                    is a temporary work permit visa under the International Mobility Program issued to foreign entrepreneurs and investors who intend to work as self-employed individuals who can run a business in Canada.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <p>
                                    <span>INTRA-COMPANY TRANSFER </span> 
                                    is temporary work permit under the International Mobility Program that allows qualified foreign business owners to transfer their businesses to Canada and acquire a work permit.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default LmiaExemptWorkPermit;
