import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function ReconsideraRequest() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };


    return (

        <>
            <div className="page-hero recon">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>RECONSIDERATION REQUEST</h1>
                            </div>
                        </div>
                        <div className="introduce">

                        </div>
                    </div>
                </div>
            </div>


            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                WHAT IS A RECONSIDERATION     REQUEST?
                                </a>
                            </li>
                            <li>
                                <a href="#content_reqest" onClick={(e) => { e.preventDefault(); scrollToSection('content_reqest'); }}>
                                CONTENT OF THE REQUEST
                                </a>
                            </li>
                            <li>
                                <a href="#service" onClick={(e) => { e.preventDefault(); scrollToSection('service'); }}>
                                    OUR SERVICES
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="two">
                            <h1>
                            WHAT IS A RECONSIDERATION REQUEST?
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                           <p>
                           A reconsideration request is an informal process through which an applicant may request a visa officer to reconsider a previously rejected immigration application. In the reconsideration request, the applicant must explain why they think the visa officer made a mistake.
                           </p>
                           <p>
                           The main advantage of a reconsideration request is that it can be submitted via webform through a case specific inquiry to Immigration, Refugees and Citizenship Canada, making it a straightforward option compared to other legal avenues. It's important to note, however, that visa officers hold a significant degree of discretion when assessing reconsideration requests. The likelihood of success depends on the extent of the error in the motives for refusal of your application.
                           </p>
                        </div>
                        <div className="heading">
                            <h1 id='content_reqest'>
                            CONTENT OF THE REQUEST 
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                            In building a reconsideration request, it is crucial to :
                            </p>
                            <p>
                                <ul>
                                    <li>clearly state your desired outcome</li>
                                    <li>identify and address any factual or legal error made by the officer</li>
                                    <li>present additional evidence if new facts have emerged since your initial application</li>
                                    <li>explain how these new developments are relevant to your case and why they warrant a reconsideration.</li>
                                </ul>
                            </p>
                            <p>
                            Lastly, if there are compelling reasons beyond the applicant’s control that contributed to the refusal, such as the death of a close family member, third-party delays, or natural disasters, they need to provide evidence to support these claims and explain how these exceptional circumstances impacted their immigration application.    
                            </p>
                            <p>
                            One must remember that the visa officer will review your request and decide whether to reopen your file. A well-written, polite and respectful request can increase the likelihood of a favourable decision.   
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='service'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                            A reconsideration request will only be accepted when the circumstances are justified. At M&C Premier Immigration, our lawyers can help you identify errors and advocate for your application to be reconsidered and accepted. The legal fees of reconsideration requests are dependent on the complexity of the case.
                            </p>
                        
                            <p>
                                <em>
                                Book a consultation and find out whether a reconsideration request is the right avenue for your refusal.
                                </em>
                            </p>

                    


                        </div>
                    </div>





                </div>
            </div>
        </>
    )
}
