import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function AtlanticImmigrationProgram() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };
    return (
        <>
            <div className="page-hero atlanti">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>ATLANTIC IMMIGRATION PROGRAM</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The Atlantic Immigration Program offers a route to permanent residency for skilled foreign workers and graduates from Canadian institutions who wish to reside and work in any of Canada's four Atlantic provinces : New Brunswick, Nova Scotia, Prince Edward Island, or Newfoundland and Labrador. This initiative assists employers in recruiting suitable candidates for positions they have struggled to fill locally.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#two" onClick={(e) => { e.preventDefault(); scrollToSection('two'); }}>
                                    ELIGIBILITY CRITERIA
                                </a>
                            </li>
                            <li >
                                <a href="#joboffer" onClick={(e) => { e.preventDefault(); scrollToSection('joboffer'); }}>
                                    JOB OFFER
                                </a>
                            </li>
                            <li>
                                <a href="#service" onClick={(e) => { e.preventDefault(); scrollToSection('service'); }}>
                                    OUR SERVICES
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="two">
                            <h1>
                                ELIGIBILITY CRITERIA
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                To be eligible for the program, you need to <b>secure a job offer</b> from an approved employer in <b>Atlantic Canada</b>. This program targets recent graduates from a recognized post-secondary institution in Atlantic Canada or skilled workers. Whether you currently reside abroad or are already in Canada as a temporary resident, you can apply.
                            </p>
                            <p>
                                To qualify for the Atlantic Immigration Program, you must:
                            </p>
                            <p>
                                <ul>
                                    <li>Have relevant work experience, unless you're an international graduate from a recognized post-secondary institution in Atlantic Canada.</li>
                                    <li>Meet or surpass the educational criteria.</li>
                                    <li>Meet or surpass the language proficiency standards.</li>
                                    <li>Demonstrate sufficient funds to support yourself and your family upon arrival in Canada – if you're already residing and working in Canada with a valid work permit, you're exempt from providing proof.</li>
                                </ul>
                            </p>
                            <p>
                                A candidate who meets these requirements can begin seeking employment with a designated employer in Atlantic Canada.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='joboffer'>
                                JOB OFFER
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                You'll need to explore job opportunities with specific employers designated by each province, including New Brunswick, Newfoundland and Labrador, Nova Scotia, and Prince Edward Island.
                            </p>
                            <p>
                                The job offer must meet the following criteria:
                            </p>
                            <p>
                                <ul>
                                    <li>It is for full-time employment.</li>
                                    <li>It is non-seasonal, indicating consistent and regular paid work throughout the year.</li>
                                    <li>For job offers falling under NOC 2021 categories of TEER 0, 1, 2, or 3, it must be lasting at least one (1) year from your acquisition of permanent residency.</li>
                                    <li>For job offers categorized under NOC 2021 TEER 4, it must entail permanent employment without a predetermined end date.</li>
                                    <li>It does not originate from a company where you, as the applicant, or your spouse/common-law partner are majority owners.</li>
                                    <li>It aligns with, or exceeds, the skill level of the work experience that qualified you for the job, unless you're an international graduate from an accredited post-secondary institution in Atlantic Canada.</li>
                                </ul>
                            </p>
                            <p>
                                Certain <b>healthcare sector</b> positions may not require a job offer at the same or higher skill level as the qualifying work experience : experience in NOC 32101 (licensed practical nurses) and NOC 31301 (registered nurses) can qualify if candidates receive an offer in NOC 33102 (nurse aides, orderlies, and patient service associates) or in NOC 44101 (home health care workers).
                            </p>
                            <p>
                                When a designated employer extends a job offer, they will provide you with an <em>"Offer of Employment to a Foreign National"</em>  form.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='service'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The process of verifying your eligibility and building an application is detail-oriented, and can be time-consuming. To ensure accuracy in evaluating your profile and to prevent any delays, extra expenses, or application rejection, it's advisable to hire immigration lawyers to handle your application preparation, maximizing your chances of success.
                            </p>
                            <p>
                                <ul className='special'>
                                    <li>
                                        <em><span>ELIGIBILITY </span></em>Initially, the team at M&C Premier Immigration will assess about your eligibility for the AIP by asking you a series of specific questions.
                                    </li>
                                    <li>
                                        <em><span>JOB OFFER </span></em>If you meet the eligibility criteria, our lawyers will assist you in reaching out to employers to secure a job offer that aligns with the program's requirements.
                                    </li>
                                    <li>
                                        <em><span>DOCUMENT PREPARATION </span></em>Upon receiving a valid job offer, our legal team will gather the necessary documentation from you to apply for permanent residence in Atlantic Canada under the program. This includes preparing a settlement plan and obtaining a certificate of endorsement.
                                    </li>
                                    <li>
                                        <em><span>PERMANENT RESIDENCE </span></em>Our lawyers will proceed to complete the requisite forms required for your permanent residence application and submit the application on your behalf.
                                    </li>
                                    <li>
                                        <em><span>WORK PERMIT (optional) </span></em>If applicable, we will assist you in applying for a work permit while your permanent residence application is under review.
                                    </li>
                                    <li>
                                        <em><span>POST-APPLICATION ASSISTANCE </span></em>Following your application submission, M&C Premier Immigration will continue to support you, providing guidance on what to anticipate throughout the process, including biometrics and medical examinations.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                <em>
                                    Book a consultation to assess your eligibility!
                                </em>
                            </p>

                            {/* <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/atlanticimm/04.jpg', alt: 'Image 1' },
                                            { src: '../assets/atlanticimm/03.jpg', alt: 'Image 2' },
                                            { src: '../assets/atlanticimm/02.jpg', alt: 'Image 3' },
                                            { src: '../assets/atlanticimm/01.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div> */}


                        </div>
                    </div>





                </div>
            </div>
        </>
    )
}
