import React, { useEffect, useState } from 'react';
import '../Css/Pages.css';
export default function IntraComapny() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };





    return (
        <>
            <div className="page-hero intra">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>INTRA-COMPANY TRANSFER</h1>
                            </div>
                        </div>
                        <div className="introduce">
                            <p>
                                The intra-company transfer (ICT) program is designed to attract established companies from all around the world to expand their operations to Canada, and transfer qualified employees (“transferees”) for the purpose of improving management effectiveness and enhancing competitiveness in overseas markets.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content">
                <div className="main">
                    <div className="menu">
                        <ul>
                            <li >
                                <a href="#question" onClick={(e) => { e.preventDefault(); scrollToSection('question'); }}>
                                    GENERAL REQUIREMENTS
                                </a>
                            </li>
                            <li >
                                <a href="#requirmets" onClick={(e) => { e.preventDefault(); scrollToSection('requirmets'); }}>

                                    WHAT IS A QUALIFIED EMPLOYEE
                                </a>
                            </li>
                            <li>
                                <a href="#funds" onClick={(e) => { e.preventDefault(); scrollToSection('funds'); }}>
                                    FUNDS
                                </a>
                            </li>
                            <li>
                                <a href="#permenent-residance" onClick={(e) => { e.preventDefault(); scrollToSection('permenent-residance'); }}>
                                    PERMANENT RESIDENCE PATHWAY
                                </a>
                            </li>
                            <li>
                                <a href="#servises" onClick={(e) => { e.preventDefault(); scrollToSection('servises'); }}>
                                    OUR SERVICES
                                </a>
                            </li>

                        </ul>
                    </div>
                    <div className="details">
                        <div className="heading" id="question">
                            <h1>

                                GENERAL REQUIREMENTS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Under this stream, applicants can establish a Canadian subsidiary, parent, branch or affiliate of their business, and <b>apply for a work permit</b> to take over the newly established operations in Canada. If the company and the transferred employee meet the requirements of the program, a work permit can be issued.
                            </p>
                            <p>
                                In most cases, the transferee’s spouse and children will also obtain an open work permit or a study permit to accompany them while they establish the company in Canada. An ICT work permit is usually issued for one (1) to three (3) years, and may be renewed for a total period of five (5) to seven (7) years depending on the transferee’s qualifications.
                            </p>
                        </div>
                        <div className="table">
                            <div className="table-clumn">
                                <p>
                                    Entrepreneurs, business owners, and shareholders of companies who hold an <strong> executive position </strong> in that company and who intend to do similar work in Canada.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <p>
                                    <strong>Senior managers and functional managers </strong>who are working in a foreign company and intend to occupy a similar position in Canada.
                                </p>
                            </div>
                            <div className="table-clumn">
                                <p>
                                    Key employees of a business who have <strong>advanced specialized knowledge</strong> that may be qualified as unique or uncommon.
                                </p>
                            </div>
                        </div>
                        <div className="heading">
                            <h1 id='requirmets'>
                                WHAT IS A QUALIFIED EMPLOYEE
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                In the context of the intra-company transfer program, qualified employees encompass executive positions, senior managers and functional managers, as well as advanced specialized knowledge.
                            </p>
                            <p>
                                To qualify for an intracompany transfer, the company and the transferee must meet the following general requirements
                            </p>
                            <p>
                                <ul>
                                    <li>The Canadian company must be a parent, subsidiary, branch or affiliate of that company at the time of the application.</li>
                                    <li>The company must be able to support the operations of the Canadian company.</li>
                                    <li>The company must have been operating for a minimum of twelve (12) months – and ideally, three (3) years.</li>
                                    <li>The applicant seeking for a work permit must have been working at the company for at least twelve (12) months in the past three (3) years.</li>
                                </ul>
                            </p>
                            <p>
                                The visa officer may also assess whether there is a <b>business plan</b>  demonstrating that the Canadian operations can generate enough revenue to cover costs and compensate its employees, and whether the expansion will result in <b>job creation for Canadians</b>. Depending on the <b>qualified employee</b> seeking to be transferred, the visa officer can also assess whether the company will be large enough to require an executive position or a senior or functional manager in Canada.
                            </p>
                            <p>
                                While there is no legal requirement to be profitable in the first year, the company must be genuine and actively engaged in business, have a physical location, and employ at least one Canadian employee who is a permanent resident or a Canadian citizen in the first year.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='funds'>
                                FUNDS
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                The government of Canada does not set a minimum investment for companies expanding their business to Canada. However, the company must have the required capital to support their new operations in Canada, compensate their staff and hire local talent.
                            </p>
                            <p>
                                The company must also show evidence of additional financial resources or assets available to sustain the Canadian business in case it does not achieve self-sufficiency by the end of the first year, in addition to the initial investment capital.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='permenent-residance'>
                                PERMANENT RESIDENCE PATHWAY
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                Under this program, the foreign worker transferred within a corporation is an excellent candidate for permanent residency. After twelve (12) months of work experience, they can become eligible under the Express Entry application for permanent residence as a Federal Skilled Worker. Once they become a permanent resident, they can continue working as an employee or a business owner.
                            </p>
                        </div>
                        <div className="heading">
                            <h1 id='visite_servises'>
                                OUR SERVICES
                            </h1>
                            <hr />
                        </div>
                        <div className="text-mid">
                            <p>
                                <ul className='special'>
                                    <li>
                                        <em><span>INCORPORATE YOUR COMPANY IN CANADA  </span></em> M&C Premier Immigration handles the legal process of registering your company in Canada, ensuring compliance with all regulatory requirements.
                                    </li>
                                    <li>
                                        <em><span>BUSINESS PLAN </span></em> Our lawyers collaborate with you to develop a detailed business plan that outlines your goals, strategies, market analysis, and financial projections to ensure the success of the expansion of your business in Canada.
                                    </li>
                                    <li>
                                        <em><span> MARKET RESEARCH  </span></em> We perform comprehensive market research in the industry sector relevant to your business expansion in Canada. This process aims to offer valuable insights into market trends, competitive landscape, and potential business opportunities.
                                    </li>
                                    <li>
                                        <em><span> HIRING PLAN  </span></em> We assist in developing a hiring plan tailored to your business needs, including recruitment strategies, job descriptions, and compliance with employment laws in Canada.
                                    </li>
                                    <li>
                                        <em><span> EXIT STRATEGY </span></em> We help you develop a strategic exit plan that outlines the process for exiting or selling your business in the future, ensuring a smooth transition and maximizing returns on your investment.
                                    </li>
                                    <li>
                                        <em><span> FINANCIAL PROJECTIONS </span></em> We work with you to establish realistic cash flow projections aligned with industry standards, ensuring financial stability and growth for your business in Canada.
                                    </li>
                                    <li>
                                        <em><span> WORK PERMIT APPLICATION </span></em> Our legal experts prepare and submit the work permit application on your behalf, ensuring all required documentation is complete and accurate to facilitate a smooth immigration process.
                                    </li>
                                </ul>
                            </p>
                            <p>
                                <em>Book and consultation with us to find out if you’re eligible!</em>
                            </p>
                            <div className="img-frame">
                                <div className="frame">
                                    <div className="image-container">
                                        {[
                                            { src: '../assets/Intercompany/04.jpg', alt: 'Image 1' },
                                            { src: '../assets/Intercompany/02.jpg', alt: 'Image 4' }
                                        ].map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.src}
                                                className={`image ${index === activeIndex ? 'yourActiveClassName' : ''}`}
                                                onClick={() => handleClick(index)}
                                                alt={image.alt}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
