import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

export default function Team() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    const [activeSection, setActiveSection] = useState(null);
    const headerHeight = 110;
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.details > div');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const top = section.offsetTop - headerHeight;
                const bottom = top + section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < bottom) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerHeight]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: topPosition - headerHeight, behavior: 'smooth' });
        } else {
            console.log('Section not found:', sectionId);
        }
    };
    return (
        <>
            <div className="page-hero team">
                <div className="main">
                    <div className="inner">
                        <div className="header">
                            <div className="border">
                            </div>
                            <div className="headings">
                                <h1>Our Team</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content team">
                <div className="main">
                    <div className="menu">
                        <div className="team">
                            <img src="../assets/team/01.jpg" alt="" />
                        </div>
                    </div>
                    <div className="details">
                        <div className="heading">
                            <h1 id='letter-of-support'>
                                Myriam Julianna Moorcroft Gobeille
                            </h1>
                            <p>
                                CO-FOUNDER – IMMIGRATION LAWYER
                            </p>
                            <hr />
                        </div>
                        <div className="cotact-deatils">
                            <div className="contact">
                                <FontAwesomeIcon icon={faLocationDot} className='contact-us-icon' />
                                <p>
                                    DIFC, Dubai,
                                    <br />
                                    United Arab Emirates
                                </p>
                            </div>
                            <div className="contact phone">
                                <FontAwesomeIcon icon={faPhone} className='contact-us-icon' />
                                <p>
                                    +971 56 988 6369
                                </p>
                            </div>
                            <div className="contact email">
                                <FontAwesomeIcon icon={faEnvelope} className="contact-us-icon email" />
                                <p>
                                    moorcroft@mcimm.com
                                </p>
                            </div>



                        </div>
                        <div className="text-mid">
                            <p>
                                Myriam Julianna Moorcroft Gobeille, a Canadian immigration attorney based in Dubai, specializes in assisting individuals and investors in their journey to relocate to Canada. Beginning her legal career in Montreal, Canada, in 2015, Myriam pursued her education and obtained her license to practice law. Throughout her professional journey, she has accumulated invaluable experience in various professional environments, including Canadian private law firms, a multinational corporation, and a respected non-profit organization.
                            </p>
                            <p>
                                Through her own experience of immigrating from Canada to the United Arab Emirates, Myriam cultivated a strong interest in immigration law. Her firsthand journey from Montreal to Dubai cultivated her deep understanding of the difficulties involved in relocating to a new country. This unique perspective, combined with her legal expertise, positions her as a trusted immigration lawyer for individuals and families in the region who are looking to establish themselves in Canada.
                            </p>
                            <p>
                                Leveraging her extensive knowledge of Canadian immigration law and the specific needs of expatriates in Dubai, Myriam provides personalized legal advice to each client's situation. Handling a diverse range of immigration matters, including skilled worker visas, business immigration, and judicial review applications, she offers her expertise and support while prioritizing clear communication with her clients.
                            </p>
                            <p>
                                Furthermore, Myriam is fervently committed to supporting entrepreneurs and facilitating the success of their business ventures in Canada. Particularly for those seeking permanent residency and citizenship, she offers expert guidance on business-related inquiries concerning Canada. Whether you're self-employed or an established business owner, Myriam ensures that you receive the highest caliber of advice on your immigration and business options in Canada.
                            </p>
                            <p>
                                Myriam firmly believes in finding pragmatic solutions for every immigration issue, dedicating her creativity and expertise to each client's situation. With her profound understanding of Canadian immigration laws and experience working with the Government of Canada, she adeptly navigates legal administrative processes. In instances of judicial review or appeals, Myriam defends her clients' cases with compelling arguments. If litigation becomes necessary, Myriam remains determined in her commitment to fiercely advocate for her clients' rights.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content team">
                <div className="main">
                    <div className="menu">
                        <div className="team">
                            <img src="../assets/team/02.PNG" alt="" />
                        </div>
                    </div>
                    <div className="details">
                        <div className="heading">
                            <h1 id='letter-of-support'>
                                Basma Chazbek
                            </h1>
                            <p>
                                CO-FOUNDER – IMMIGRATION LAWYER
                            </p>
                            <hr />
                        </div>
                        <div className="cotact-deatils">
                            <div className="contact">
                                <FontAwesomeIcon icon={faLocationDot} className='contact-us-icon' />
                                <p>
                                    Montreal, Quebec, Canada
                                </p>
                            </div>
                            <div className="contact phone">
                                <FontAwesomeIcon icon={faPhone} className='contact-us-icon' />
                                <p>
                                    +1 (514) 607-6798
                                </p>
                            </div>
                            <div className="contact email">
                                <FontAwesomeIcon icon={faEnvelope} className="contact-us-icon email" />
                                <p>
                                    chazbek@mcimm.com
                                </p>
                            </div>



                        </div>
                        <div className="text-mid">
                            <p>
                                Basma Chazbek is a Canadian attorney licensed with the Bar of Quebec. She holds a Minor in Pharmacology from McGill University and a Bachelors of Laws (LL.B.) from Université de Montréal.
                            </p>
                            <p>
                                Basma began her career as the legal counsel of a pharmaceutical company, where she gained experience in commercial law, intellectual property, as well as regulatory affairs and compliance in various industry sectors. Her transition to immigration law marked a pivotal juncture in her professional journey when she joined a private law firm in Montreal. As a litigator, she specialized in challenging immigration refusals through judicial review before the Federal Court. She also represented her clients' interests before the authorities of Immigration, Refugees and Citizenship Canada in complex immigration matters.
                            </p>
                            <p>
                                Her experience in litigation has rendered her highly proficient in foreseeing motives for refusals and provided her with deep understanding of where applications tend to be deficient. In her practice, she strives to build foolproof applications that can withstand the analytical process and scrutiny of immigration officers.
                            </p>

                            <p>
                                Basma has experience in helping both individuals and companies establish themselves in Canada, offering immigration solutions that are sensitive to her client's specific circumstances. More than just an immigration attorney, she routinely guides her clients in navigating the formalities and procedures to establish or expand their ventures in Canada. As a legal advisor, she assists her clients in ensuring that their business operations are compliant with the Canadian regulatory requirements of their industry sector.
                            </p>
                            <p>
                                With her passion for litigation and her commitment to excellence, Basma stands ready to fiercely uphold the rights of her clients, ensuring that they are not just protected, but are propelled towards success in their business or professional endeavours in Canada.
                            </p>


                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

function Myriam() {
    return (
        <>
            <>
                <div className="card">
                    <h2>
                        MYRIAM JULIANNA MOORCROFT GOBEILLE
                    </h2>
                    <hr />
                    <p>
                        Myriam Julianna Moorcroft Gobeille, a Canadian immigration attorney based in Dubai, specializes in assisting individuals and investors in their journey to relocate to Canada. Beginning her legal career in Montreal, Canada, in 2015, Myriam pursued her education and obtained her license to practice law. Throughout her professional journey, she has accumulated invaluable experience in various professional environments, including Canadian private law firms, a multinational corporation, and a respected non-profit organization.
                    </p>
                    <p>
                        Through her own experience of immigrating from Canada to the United Arab Emirates, Myriam cultivated a strong interest in immigration law. Her firsthand journey from Montreal to Dubai cultivated her deep understanding of the difficulties involved in relocating to a new country. This unique perspective, combined with her legal expertise, positions her as a trusted immigration lawyer for individuals and families in the region who are looking to establish themselves in Canada.
                    </p>
                    <p>
                        Leveraging her extensive knowledge of Canadian immigration law and the specific needs of expatriates in Dubai, Myriam provides personalized legal advice to each client's situation. Handling a diverse range of immigration matters, including skilled worker visas, business immigration, and judicial review applications, she offers her expertise and support while prioritizing clear communication with her clients.
                    </p>
                    <p>
                        Furthermore, Myriam Moorcroft is fervently committed to supporting entrepreneurs and facilitating the success of their business ventures in Canada. Particularly for those seeking permanent residency and citizenship, she offers expert guidance on business-related inquiries concerning Canada. Whether you're self-employed or an established business owner, Myriam ensures that you receive the highest caliber of advice on your immigration and business options in Canada.
                    </p>
                    <p>
                        Myriam firmly believes in finding pragmatic solutions for every immigration issue, dedicating her creativity and expertise to each client's situation. With her profound understanding of Canadian immigration laws and experience working with the Government of Canada, she adeptly navigates legal administrative processes. In instances of judicial review or appeals, Myriam defends her clients' cases with compelling arguments. If litigation becomes necessary, Myriam remains determined in her commitment to fiercely advocate for her clients' rights.
                    </p>
                </div>
            </>
        </>
    )
}

function Basma() {
    return (
        <>
            <div className="card">
                <h2>
                    BASMA CHAZBEK
                </h2>
                <hr />
                <p>
                    Basma Chazbek is a Canadian attorney licensed with the Bar of Quebec. She holds a Minor in Pharmacology from McGill University and a Bachelors of Laws (LL.B.) from Université de Montréal.
                </p>
                <p>
                    Basma began her career with the legal counsel of a pharmaceutical company, where she gained experience in commercial law, intellectual property, as well as regulatory affairs and compliance in various industry sectors. Her transition to immigration law marked a pivotal juncture in her professional journey when she joined a private law firm in Montreal. As a litigator, she specialized in challenging immigration refusals through judicial review before the Federal Court. She also represented her clients' interests before the authorities of Immigration, Refugees and Citizenship Canada in complex immigration matters.
                </p>
                <p>
                    Her experience in litigation has rendered her highly proficient in foreseeing motives for refusals, and provided her with deep understanding of where applications tend to be deficient. In her practice, she strives to build foolproof applications that can withstand the analytical process and scrutiny of immigration officers.
                </p>
                <p>
                    Basma has experience in helping both individuals and companies establish themselves in Canada, offering immigration solutions that are sensitive to her client's specific circumstances. More than just an immigration attorney, she routinely guides her clients in navigating the formalities and procedures to establish or expand their ventures in Canada. As a legal advisor, she assists her clients in ensuring that their business operations are compliant with the Canadian regulatory requirements of their industry sector.
                </p>
                <p>
                    With her passion for litigation and her commitment to excellence, Basma stands ready to fiercely uphold the rights of her clients, ensuring that they are not just protected, but are propelled towards success in their business or professional endeavors in Canada.
                </p>
            </div>
        </>
    )
}

