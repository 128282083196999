import React, { useEffect, useState, useRef } from 'react';
import '../Css/WhoWe.css';

export default function WhoWe() {
    const [sliderWidth, setSliderWidth] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [current, setCurrent] = useState(0);
    const [target, setTarget] = useState(0);

    const animationRef = useRef(null);

    useEffect(() => {
        const images = document.querySelectorAll('.img');
        const slider = document.querySelector('.slider');

        const init = () => {
            const slider = document.querySelector('.slider');
            const width = slider.getBoundingClientRect().width;
            const maxWidth = 300;
            const height = Math.min(width, maxWidth);

            setSliderWidth(width);
            setImageWidth(width / images.length);
            slider.style.maxHeight = `${maxWidth}px`;
            document.body.style.height = `${height - (window.innerWidth - window.innerHeight)}px`;
        };

        const lerp = (start, end, t) => {
            return start * (1 - t) + end * t;
        };

        const animateImages = () => {
            let ratio = current / imageWidth;
            let intersectionRatioValue;

            images.forEach((image, idx) => {
                intersectionRatioValue = ratio - (idx * 0.7);
                image.style.transform = `translateX(${intersectionRatioValue * 70}px)`;
            });
        };

        const animate = () => {
            setCurrent((prevCurrent) => {
                return parseFloat(lerp(prevCurrent, target, 0.05)).toFixed(2);
            });
            setTarget(window.scrollY);
            animateImages();
            animationRef.current = requestAnimationFrame(animate);
        };

        const handleResize = () => {
            init();
        };

        window.addEventListener('resize', handleResize);

        init();
        animate();

        return () => {
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationRef.current);
        };
    }, [current, imageWidth, target]);

    return (
        <div className="who-we">
            <div className="img-main">
                <div className="slider">
                    <div className="slider-inner">
                        {[...Array(20)].map((_, idx) => (
                            <div className="item1" key={idx}>
                                <div className="img" style={{ backgroundImage: `url(../assets/images1/img${idx + 1}.jpg)` }}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="img-main main-1">
                <div className="slider">
                    <div className="slider-inner">
                        {[...Array(20)].map((_, idx) => (
                            <div className="item1" key={idx}>
                                <div className="img" style={{ backgroundImage: `url(../assets/images1/img${idx + 1}.jpg)` }}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="img-main main-2">
                <div className="slider">
                    <div className="slider-inner">
                        {[...Array(20)].map((_, idx) => (
                            <div className="item1" key={idx}>
                                <div className="img" style={{ backgroundImage: `url(../assets/images1/img${idx + 1}.jpg)` }}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
