import '../Css/ContactUs.css';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons';


export default function ContactUs() {
  const [popup, setPop] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phoneNumber: '',
    message: ''
  });

  useEffect(() => {
    if (popup) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling
    }
  }, [popup]);

  const handleClickOpen = () => {
    setPop(!popup);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Handle form submission here
      console.log(formData);
    }
  }



  const validateForm = () => {
    const { name, email, subject, message } = formData;
    if (name === "" || email === "" || subject === "" || message === "") {
      alert("Please fill in all fields.");
      return false;
    }
    return true;
  }

  const closePopup = () => {
    setPop(false);
  }
  return (
    <>
      <div className="contact-us" id='contact'>
        <div className="main">
          <div className="heading">
            <h1>
              Contact Us
            </h1>
            <hr />
          </div>
          <div className="contact-us-section">
            <div className="details-part">
              {/* Deatails */}
              <div className="details">
                <div className="sub">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLocationDot} className='contact-us-icon' />
                  </div>
                  <div className="text">
                    <h4>
                      Canada
                    </h4>
                    <p>
                      Montreal, Quebec, Canada.
                    </p>
                  </div>
                </div>

              </div>
              <hr />
              {/* Deatails */}
              <div className="details">
                <div className="sub">
                  <div className="icon">
                    <FontAwesomeIcon icon={faLocationDot} className='contact-us-icon' />
                  </div>
                  <div className="text">
                    <h4>
                      UAE
                    </h4>
                    <p>
                      Dubai International Financial Centre,
                      <br />
                      Dubai, United Arab Emirates
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              {/* Deatails */}
              <div className="details">
                <div className="sub">
                  <div className="icon">
                    <FontAwesomeIcon icon={faWhatsapp} className="contact-us-icon whatsApp" />
                  </div>
                  <div className="text">
                    <h4>
                      WhatsApp
                    </h4>
                    <p className="button" typeof='button' onClick={() => window.open("https://api.whatsapp.com/send?phone=971569886369", "_blank")}>
                      +971 56 988 6369
                    </p>
                    <p className="button" typeof='button' onClick={() => window.open("https://api.whatsapp.com/send?phone=15146076798", "_blank")}>
                      +1 (514) 607 6798
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              {/* Deatails */}
              <div className="details">
                <div className="sub">
                  <div className="icon">
                    <FontAwesomeIcon icon={faClock} className="contact-us-icon whatsApp" />
                  </div>
                  <div className="text">
                    <h4>
                      Monday To Friday
                    </h4>
                    <p>
                      9am to 7pm
                    </p>
                  </div>

                </div>
              </div>
              {/* Deatails Email*/}
              <div className="details email">
                <div className="sub button" typeof='button' onClick={() => window.open("mailto:info@mcpremierimmigration.com", "_blank")}>
                  <div className="icon">
                    <FontAwesomeIcon icon={faEnvelope} className="contact-us-icon" />
                  </div>
                  <div className="text">
                    <h4>
                      Email Us
                    </h4>
                    <p>
                      info@mcimm.com
                    </p>
                  </div>

                </div>
              </div>
            </div>
            <div className="form-part">
              <div className="sub" onClick={(e) => e.stopPropagation()}>
                <h2>
                  Form
                </h2>
                <hr />
                <p>
                  Haven't registered with us yet? Complete the contact form, and one of our immigration specialists will reach out to initiate your application process. Our licensed Canadian lawyers are available to guide and support you at every stage. For any inquiries, feel free to send us a message.
                </p>
                <form id="contactForm" onSubmit={handleSubmit}>
                  <input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                  <input type="tel" name="phoneNumber" placeholder="Phone Number" value={formData.phoneNumber} onChange={handleChange} required />
                  <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                  <input type="text" name="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
                  <textarea name="message" rows="4" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
                  <input type="submit" value="SEND MESSAGE" className="btn-submit" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
